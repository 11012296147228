import React, { useEffect, useState } from "react";

import Api from "../shared/api";
import { Link } from "react-router-dom";
import Simplert from "react-simplert";

const api = new Api();

const availableLanguages = [
  {
    id: 1,
    slug: "fr",
    label: "Français",
  },
  {
    id: 2,
    slug: "nl",
    label: "Néerlandais",
  },
  {
    id: 3,
    slug: "en",
    label: "English",
  },
];

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [displayConfirm, setDisplayConfirm] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);

  useEffect(() => {
    const getClients = async () => {
      try {
        const {
          data: {
            clients,
            metadata: {
              current_page,
              first_page,
              last_page,
              total_pages,
              total_records,
            },
          },
        } = await api.getClients();
        setClients(clients);
      } catch (e) {
        console.log(e);
      }
    };

    getClients();
  }, []);

  const confirmDeleteClient = (id) => {
    setDisplayConfirm(true);
    setCurrentClient(id);
  }

  const deleteClient = async () => {
    try { 
      await api.deleteClient(currentClient);
      setClients(clients.filter(client => client.id !== currentClient));
      setDisplayConfirm(false);
      setCurrentClient(null)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div>
      <section className="section">
        <div className="columns">
          <div className="column is-10">
            <h1 className="title is-size-2">Clients</h1>
          </div>
          <div className="column is-2">
            <Link
              to="/clients/new"
              className="button is-outlined is-pulled-right is-link"
            >
              <span className="icon is-small">
                <i className="fa fa-plus edition-left-icon"></i>
              </span>
              &nbsp;Créer un nouveau client
            </Link>
          </div>
        </div>
        <div className="columns is-multiline">
          <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th width="40%">Nom</th>
                <th width="30%">Code postaux</th>
                <th width="20%">Languages</th>
                <th width="10%">Actions</th>
              </tr>
            </thead>
            <tbody>
              {clients &&
                clients.length > 0 &&
                clients.map(({ name, id, postal_codes, languages }) => {
                  return (
                    <tr key={id}>
                      <td> {name} </td>
                      <td> {postal_codes.join(",")}</td>
                      <td> {languages.join(",")}</td>
                      <td>
                        <Link className="button is-outlined is-link mr-5" to={`/clients/${id}/edit`}>
                          <span className="icon is-small">
                          <i className="fa fa-pencil"/>
                          </span>
                        </Link>
                        <button className="button is-outlined is-danger" onClick={ () => confirmDeleteClient(id)}>
                          <span className="icon is-small">
                            <i className="fa fa-trash"/>
                          </span>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Simplert
          showSimplert={displayConfirm}
          type="warning"
          title="Attention"
          message="Vous êtes sur le point d'effacer un client. Êtes-vous certain?"
          useConfirmBtn={true}
          customConfirmBtnText="Je confirme"
          customCloseBtnText="Annuler"
          onConfirm={deleteClient}
        />
      </section>
    </div>
  );
};

export default Clients;
