const arrayfy = function(object){
    let array = [];
    if (typeof object === "array") {
        return object.filter( (elem) => { return elem !== null;});
    }
    const keys = Object.keys(object);
    for (let i = 0;  i < keys.length; i++) {
        let key = keys[i];
        let objectElement = object[key];
        if (objectElement !== null) {
            array.push(objectElement);
        }

    }
    return array;
};

export { arrayfy };


const formatDate = function(timestamp){
    if (!timestamp) { return ""; }
    let date = new Date(timestamp);
    let dd = date.getDate();

    let mm = date.getMonth()+1;
    const yyyy = date.getFullYear();
    if(dd<10)
    {
        dd=`0${dd}`;
    }

    if(mm<10)
    {
        mm=`0${mm}`;
    }

    return `${mm}/${dd}/${yyyy}`;
};

export { formatDate };
