import React from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";

import { stringToSlug } from "../shared/string_to_slug";
import {  useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const availableLanguages = [
  {
    id: 1,
    slug: "fr",
    label: "Français",
  },
  {
    id: 2,
    slug: "nl",
    label: "Néerlandais",
  },
  {
    id: 3,
    slug: "en",
    label: "English",
  },
];

const ClientForm = ({ client, onSubmit }) => {
  const { register, handleSubmit, errors } = useForm();
  const [postal_codes, setTags] = React.useState(client.postal_codes);

  const preSubmit = async ({name, languages}) => {
    try {
      const reference = stringToSlug(name);
      onSubmit({name, languages, postal_codes, reference})
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(preSubmit)}>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Nom du client</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control is-expanded">
                <input
                  name="name"
                  defaultValue={client.name}
                  ref={register({ required: true })}
                  type="text"
                  className="input"
                />
                {errors["name"] && <span className="form-error">Ce champs est requis.</span>}
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Code postaux</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control is-expanded">
                <ReactTagInput
                  tags={postal_codes}
                  onChange={(newTags) => setTags(newTags)}
                  removeOnBackspace
                  placeholder="Ecriver et appuyer sur enter"
                  validator={(value) => {
                    const isValidNumber = Number(value);
                    if (!isValidNumber) {
                      alert("Utilisez uniquement des chiffres");
                      return null;
                    }
                    return value;
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Langues</label>
          </div>
          <div className="field-body">
            <div className="field">
              {availableLanguages.map(({ id, label, slug }) => (
                <div className="control is-expanded" key={id}>
                  <input
                    className="is-checkradio"
                    id={`language_${slug}`}
                    type="checkbox"
                    name="languages"
                    value={slug}
                    ref={register}
                    defaultChecked={client.languages.includes(slug)}
                  />
                  <label htmlFor={`language_${slug}`}>{label}</label>
                </div>
              ))}
              {errors["languages"] && <span className="form-error">Ce champs est requis.</span>}
            </div>
          </div>
        </div>
        <div className="section columns is-centered">
          <div className="columns is-12">
            <div className="control" style={{ marginRight: 10}}>
              <input
                type="submit"
                className="button is-primary"
                value="Sauver"
              />
            </div>
            <div className="control">
              <Link className="button is-light" to="/clients">
                Annuler
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ClientForm;
