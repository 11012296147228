import React from "react";
import { Link } from "react-router-dom";
import { EMPTY_INSTITUTION } from "./config";
import { useHistory } from 'react-router-dom';
import {institution_addictions, institution_accessibilities, institution_prices, institution_languages} from "../shared/institution_attributes";

import { InstitutionForm } from "./InstitutionForm";
import Api from "../shared/api";
import { withServices } from "../../shared/services_provider";

const api = new Api();

const NewInstitution = ({ services, serviceGroups }) => {
  const history = useHistory();

  const _updateInstitutionText = (attributeName, values, institution) => {
    institution[attributeName].fr = values[`${attributeName}_fr`];
    institution[attributeName].nl = values[`${attributeName}_nl`];
    institution[attributeName].en = values[`${attributeName}_en`];
    return institution;
};

  const _updateInstitutionServices = (values, institution) => {
    institution.services = [];
    let serviceKeys = Object.keys(values);
    serviceKeys.map( (key) => {
        let attributes = key.split('_');
        let attributeName = attributes[0];
        if (attributeName !== 'serviceId') { return null; }

        let value = values[key];
        if (value === true) {
            let serviceId = parseInt(attributes[1]); // serviceId_1
            institution.services.push(serviceId);
            return serviceId;
        }
        return null;
    });

    return institution;
};

  const updateInstitutionAttributes = (values, institution, keys) => {
    let selectedValues = [];
    keys.map( (key) => {
        let value = values[key];
        if (value === true) {
            let ref = key.split('_').splice(1).join('_'); // serviceId_1
            selectedValues.push(ref);
        }
    });
    return selectedValues;
};

  const updateInstitutionAddictions = (values, institution) => {
    institution.addictions = [];
    let keys = institution_addictions.map( (a) =>  { return `addictionsRef_${a.reference}`});
    institution.addictions = updateInstitutionAttributes(values, institution, keys);
    return institution;
};

  const updateInstitutionPrices = (values, institution) => {
    let keys = institution_prices.map( (p) =>  { return `pricesRef_${p.reference}`});
    institution.prices = updateInstitutionAttributes(values, institution, keys);
    return institution;
};

  const updateInstitutionAccessibilities = (values, institution) => {
    let keys = institution_accessibilities.map( (a) =>  { return `accessibilitiesRef_${a.reference}`});
    institution.accessibilities = updateInstitutionAttributes(values, institution, keys);
    return institution;
};

  const updateInstitutionLanguages = (values, institution) => {
    institution.languages = [];
    institution_languages.map( (language) => {
        if (values[`languages${language.reference}`]) {
            institution.languages.push(language.reference)
        }
    })
    return institution;
};

  const submit = async (values) => {
    try {
      const { main_language , phone, web, legal_id, hospital, prm, cpas, name_fr, name_en, name_nl, description_fr, description_en, description_nl } = values;
        let institutionToSend = {
          main_language,
          phone,
          web,
          legal_id,
          hospital: hospital === "true",
          prm: prm === "true",
          cpas: cpas === "true",
          name: {
            fr: name_fr,
            en: name_en,
            nl: name_nl,
          },
          description: {
            fr: description_fr,
            en: description_en,
            nl: description_nl,
          }
        }
        institutionToSend = updateInstitutionLanguages(values, institutionToSend);
        institutionToSend = _updateInstitutionServices(values, institutionToSend);
        institutionToSend = updateInstitutionAddictions(values, institutionToSend);
        institutionToSend = updateInstitutionAccessibilities(values, institutionToSend);
        institutionToSend = updateInstitutionPrices(values, institutionToSend);

        const { data: { institution } } = await api.createInstitution(institutionToSend);
        history.push(`/institutions/${institution.id}`);
    } catch(e) {
        console.log(e);
        console.log('it failed');
    }
  };

  const cancelURL = "/institutions";

  return (
    <div className="section">
      <div className="columns">
        <div className="column is-11">
          <h1 className="title is-size-2">
            <Link className="icon subtitle-left-icon" to="/institutions">
              <i className="fa fa-angle-left"></i>
            </Link>
            Nouvelle institution
          </h1>
        </div>
      </div>
      <div>
        <InstitutionForm
          institution={EMPTY_INSTITUTION}
          addictions={institution_addictions}
          accessibilities={institution_accessibilities}
          prices={institution_prices}
          languages={institution_languages}
          services={serviceGroups}
          onSubmit={submit}
          cancelURL={cancelURL}
        />
      </div>
    </div>
  );
};

export default withServices(NewInstitution);
