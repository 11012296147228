import React from 'react'

let RouteCard = props => {
    const { route, keyIndex } = props;

    const style = {
        color: `#${route.textColor}`,
        backgroundColor: `#${route.color}`,
        display: 'inline-grid',
        margin: '3px 3px',
        padding: '3px 9px',
        borderRadius: '3px'
    };

    return (
        <div style={style} key={keyIndex} >
            { route.shortName }
        </div>
    )
};

export { RouteCard }




