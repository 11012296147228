import React from 'react';
import { useForm } from 'react-hook-form';

import {DAYSMAPPINGS} from '../../shared/constants';

let ScheduleForm = props => {
    const { handleSubmit, register, errors } = useForm();
    const {onSubmit, schedule, scheduleIndex,  addresses } = props;

    let textInput = (name, label, defaultValue, required) => {
        const labelName = required ? `${label} *` : label;
        return (
            <div className="field is-horizontal">
                <input type="hidden" name="scheduleIndex" value={scheduleIndex} ref={register({ required: required })} />
                <div className="field-label is-normal">
                    <label className="label">{labelName}</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control">
                            <input name={name} ref={register({ required: required })}  type="text" className="input" defaultValue={defaultValue} />
                            {required && errors[name] && <span className="form-error">Ce champs est requis.</span>}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    if (schedule === undefined) {
        return (<div />)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="box">
                <h3 className="title is-4">Informations générales</h3>
                <div className="control columns">
                    <div className="column is-full">
                        <label className="label">Nom</label>
                        { textInput('name.fr', 'Français', schedule.name.fr, true) }
                        { textInput('name.nl', 'Dutch', schedule.name.nl, true) }
                        { textInput('name.en', 'English', schedule.name.en, true) }
                    </div>
                </div>
                <div className="control columns">
                    <div className="column is-full">
                        <label className="label">Adresse</label>
                        <div className="select">
                            <select name={`addressId`} ref={register({ required: true })} defaultValue={schedule.addressId}>
                                { addresses && addresses.map( (address, index) => {
                                    return (
                                        <option key={index} value={address.id}>{address.address_line_1.fr} {address.street_number}, {address.postal_code} {address.city.fr}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>

            </div>
            { DAYSMAPPINGS.map( (day, index) => {
                return (
                    <div key={index} className="box">
                        <input name={`openingHours[${index}].day`} type="hidden" value={index} ref={register({ required: true })} />
                        <h3 className="title is-4">{ day }</h3>
                        <div className="container is-fullhd">
                            <div className="control columns">
                                <div className="column is-one-fourth">
                                    <label className="label">Heure d'ourverture</label>
                                    <input className="input" type="text" name={`openingHours[${index}].from`}  ref={register({ required: false })} defaultValue={schedule.openingHours[index].from} />
                                </div>
                                <div className="column is-one-fourth">
                                    <label className="label">Heure de fermeture</label>
                                    <input className="input" type="text" name={`openingHours[${index}].to`}  ref={register({ required: false })} defaultValue={schedule.openingHours[index].to} />
                                </div>
                                <div className="column is-one-fourth">
                                    <label className="label">Pause de</label>
                                    <input className="input" type="text" name={`openingHours[${index}].closeFrom`}  ref={register({ required: false })} defaultValue={schedule.openingHours[index].closeFrom} />
                                </div>
                                <div className="column is-one-fourth">
                                    <label className="label">Pause jusqu'à</label>
                                    <input className="input" type="text" name={`openingHours[${index}].closeTo`}  ref={register({ required: false })} defaultValue={schedule.openingHours[index].closeTo} />
                                </div>
                            </div>
                            <div className="control columns">
                                <div className="column is-one-fourth">
                                    <label className="label">Ouvert 24h/24 ?</label>
                                    <input className="is-checkradio" id={`openingHours[${scheduleIndex}][${index}]_allDayOpen_true`} name={`openingHours[${index}].allDayOpen`} type="radio" value="true" defaultChecked={schedule.openingHours[index].allDayOpen === true} ref={register} />
                                    <label htmlFor={`openingHours[${scheduleIndex}][${index}]_allDayOpen_true`}>Vrai</label>

                                    <input className="is-checkradio" id={`openingHours[${scheduleIndex}][${index}]_allDayOpen_false`} name={`openingHours[${index}].allDayOpen`} type="radio" value="false" defaultChecked={schedule.openingHours[index].allDayOpen === false} ref={register} />
                                    <label htmlFor={`openingHours[${scheduleIndex}][${index}]_allDayOpen_false`}>Faux</label>
                                 </div>
                                <div className="column is-one-fourth">
                                    <label className="label">Horaire variable ?</label>
                                    <input className="is-checkradio" id={`openingHours[${scheduleIndex}][${index}]_variable_true`} name={`openingHours[${index}].variable`} type="radio" value="true" defaultChecked={schedule.openingHours[index].variable === true} ref={register} />
                                    <label htmlFor={`openingHours[${scheduleIndex}][${index}]_variable_true`}>Vrai</label>

                                    <input className="is-checkradio" id={`openingHours[${scheduleIndex}][${index}]_variable_false`} name={`openingHours[${index}].variable`} type="radio" value="false" defaultChecked={schedule.openingHours[index].variable === false} ref={register} />
                                    <label htmlFor={`openingHours[${scheduleIndex}][${index}]_variable_false`}>Faux</label>
                                </div>
                            </div>
                            <div className="control columns">
                                <div className="column is-full">
                                    <label className="label">Description</label>
                                    { textInput(`openingHours[${index}].description.fr`, 'Français', schedule.openingHours[index].description.fr, false) }
                                    { textInput(`openingHours[${index}].description.nl`, 'Dutch', schedule.openingHours[index].description.nl, false) }
                                    { textInput(`openingHours[${index}].description.en`, 'English', schedule.openingHours[index].description.en, false) }
                                </div>
                            </div>
                        </div>

                    </div>
                )
            })}
            <div className="box">
                <div className="section columns is-centered">
                    <div className="columns is-12">
                        <div className="control">
                            <input type="submit" className="button is-primary" value="Sauvegarder les modifications" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
};

export default ScheduleForm;
