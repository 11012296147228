import React from "react";
import { useHistory } from "react-router";
import Api from "../shared/api";

import ClientForm from "./Form";

const api = new Api();

const defaultClient = {
  name: '',
  languages: [],
  postal_codes: [],
}

const Clients = () => {
  const history = useHistory();
  
  const onSubmit = async ({name, languages, postal_codes, reference}) => {
    try {
      await api.createClient({name, languages, postal_codes, reference})
      history.push("/clients");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <section className="section">
        <div className="columns">
          <div className="column is-10">
            <h1 className="title is-size-2">Nouveau client</h1>
          </div>
        </div>
      </section>
      <ClientForm onSubmit={onSubmit} client={defaultClient}/>
    </div>
  );
};

export default Clients;
