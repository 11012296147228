import React, { Component } from "react";

import {firebaseDb} from '../../config/firebase/firebase';
import {Link} from "react-router-dom";
import Simplert from 'react-simplert';

import {formatDate} from '../../shared/utils';
import Api from '../shared/api';

const spaceButtonStyle = {
    margin: '0 0.5rem 0 0'
};

class Messages extends Component {

    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            showConfirmation: false,
            sendingMessageIds: []
        };

        this._askToDelete = this._askToDelete.bind(this);
        this._deleteMessage = this._deleteMessage.bind(this);
        this._publish = this._publish.bind(this);
        this.loadData = this.loadData.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try  {
            const api = new Api();
            const { data: { messages }} = await api.getMessages();
            const sortedMessages = messages.sort((a, b) => a.createdAt > b.createdAt)
            this.setState({
                messages: sortedMessages
            })
        } catch(e) {
            console.log(e)
        }
    }

    _askToDelete(messageId) {
        this.setState({showConfirmation: true, messageIdToDelete: messageId});
    }

    async _deleteMessage() {
        const api = new Api();
        const messageId = this.state.messageIdToDelete;
        const loadData = this.loadData;
        this.setState({showConfirmation: false, messageIdToDelete: null});

        api
            .deleteMessage(messageId)
            .then( async (response) => {
                //await firebaseDb.ref(`messages/${messageId}`).remove();
                loadData();
            })
            .catch((err) => {
                console.log(err)
            });


    };

    async _publish(message) {
        if (message.sent){
            return
        }
        let {sendingMessageIds} = this.state;

        if (sendingMessageIds.includes(message.id)) {
            return
        }

        sendingMessageIds.push(message.id);
        this.setState({sendingMessageIds})
        const api = new Api();
        const loadData = this.loadData;

        api
            .publishMessage(message)
            .then( async (response) => {
                let messagesRef = firebaseDb.ref(`messages/${message.id}`);
                message.sentAt = new Date().getTime();
                await messagesRef.set(message);

                sendingMessageIds = sendingMessageIds.filter(function(value, index, arr){ return value !==  message.id;});
                this.setState({sendingMessageIds});
                loadData();
            })
            .catch((err) => {
                console.log(err)
            });
    }

    render() {
        const { messages, sendingMessageIds } = this.state;

        const _askToDelete = this._askToDelete;
        const _publish = this._publish;

        return (
            <section className="section">
                <div className="columns">
                    <div className="column is-11">
                        <h1 className="title is-size-2">Messages</h1>
                    </div>
                    <div className="column is-1">
                        <Link className="button is-outlined is-pulled-right is-link" to={ `/message/new` }>
                            <span className="icon is-small">
                              <i className="fa fa-plus edition-left-icon"></i>
                            </span>
                            &nbsp;Nouveau
                        </Link>
                    </div>
                </div>


                <div className="columns is-multiline">

                    <table className="table is-striped is-hoverable is-fullwidth">
                        <thead>
                        <tr>
                            <th width="10%">Date</th>
                            <th width="20%">Titre</th>
                            <th width="50%">Message</th>
                            <th width="10%">Envoyé le</th>
                            <th width="10%">Actions</th>
                        </tr>
                        </thead>
                        <tbody>

                        { messages != null && messages.map(function(message, index){
                            return (
                                <tr key={index}>
                                    <td>{ formatDate(message.created_at) }</td>
                                    <td> {message.title.fr}  </td>
                                    <td> {message.body.fr}  </td>
                                    <td> {message.sent_at && new Date(message.sent_at).toDateString() } </td>
                                    <td>
                                        <a className="button is-outlined is-danger" style={spaceButtonStyle} href="#" onClick={ () => _askToDelete(message.id)}>
                                            <span className="icon is-small">
                                              <i className="fa fa-trash"/>
                                            </span>
                                        </a>
                                        { message.sent_at === undefined &&
                                            <a className={`button is-link ${ sendingMessageIds.includes(message.id) ? 'is-loading' : ''}`} style={spaceButtonStyle} href="#" onClick={ () => _publish(message)}>
                                                <span className="icon is-small">
                                                    <i className="fa fa-paper-plane"></i>
                                                </span>
                                            </a>
                                        }
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>

                <Simplert
                    showSimplert={this.state.showConfirmation}
                    type="warning"
                    title="Attention"
                    message="Vous êtes sur le point d'effacer un message. La notification a déjà été envoyée. Êtes-vous certain?"
                    useConfirmBtn={true}
                    customConfirmBtnText="Je confirme"
                    customCloseBtnText="Annuler"
                    onConfirm={this._deleteMessage}
                />
            </section>
        )
    }
}



export default Messages;
