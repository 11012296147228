import { Auth0Client } from "@auth0/auth0-spa-js";
import * as axios from "axios";

const api_prefix = `/api/private`;

const {
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_DUNE_API_ENDPOINT,
    REACT_APP_DUNE_API_TOKEN
} = process.env;

export default class Api {
    constructor() {
        this.api_token = null;
        this.client = null;
        this.api_url = REACT_APP_DUNE_API_ENDPOINT;
    }

    init = () => {
        this.api_token = JSON.parse(localStorage.getItem('DUNE::TOKEN'))

        let headers = {
            Accept: "application/json",
            "X-API-KEY": REACT_APP_DUNE_API_TOKEN,
        };

        if (this.api_token) {
            headers.Authorization = `Bearer ${this.api_token}`;
        }

        this.client = axios.create({
            baseURL: this.api_url,
            timeout: 31000,
            headers: headers,
        });

        this.client.interceptors.response.use((response) => response, (error) => {
            if(error.response.status === 401){
                //localStorage.removeItem('DUNE::TOKEN')
                //const client = new Auth0Client({
                //    domain: REACT_APP_AUTH_DOMAIN,
                //    client_id: REACT_APP_AUTH_CLIENT_ID
                //})
                //client.logout({ returnTo: window.location.origin })
            }
            throw error;
        });

        return this.client;
    };

    publishMessage =  (message) => {
        const data = {
            message:{
                body: message.body,
                created_at : message.createdAt,
                original_id: message.id,
                institution_id: message.institutionId,
                title:  message.title
            }
        }
        return this.init().post(`${api_prefix}/messages`, data);
    };

    deleteMessage =  (messageId) => {
        const url = `${api_prefix}/messages/${messageId}`;
        return this.init().delete(url);
    };

    getFeedbacks = (page, perPage) => {
        const url = `${api_prefix}/feedbacks?page=${page}&per_page=${perPage}`;
        return this.init().get(url);
    };

    getMessages = () => {
        const url = `${api_prefix}/messages`;
        return this.init().get(url);
    }

    getClients = () => {
        const url = `${api_prefix}/clients`;
        return this.init().get(url);
    }

    getClient = (id) => {
        const url = `${api_prefix}/clients/${id}`;
        return this.init().get(url);
    }

    createClient = ({name, reference, postal_codes, languages}) => {
        const url = `${api_prefix}/clients`;
        const data = {
            client: {
                name, reference, postal_codes, languages
            }
        }
        return this.init().post(url, data)
    }
    
    updateClient = (id, {name, postal_codes, languages}) => {
        const url = `${api_prefix}/clients/${id}`;
        const data = {
            client: {
                name, postal_codes, languages
            }
        }
        return this.init().patch(url, data)
    }

    deleteClient = (id) => {
        const url = `${api_prefix}/clients/${id}`;
        return this.init().delete(url,)
    }

    getInstitutions = ({page, per_page}) => {
        const url = `${api_prefix}/institutions?page=${page}&per_page=${per_page}`;
        return this.init().get(url);
    }

    createInstitution = (data) => {
        const url = `${api_prefix}/institutions`;
        return this.init().post(url, data);
    }

    updateInstitution = (id, data) => {
        const url = `${api_prefix}/institutions/${id}`;
        return this.init().patch(url, data);
    }
    
    getInstitutionById = (id) => {
        const url = `${api_prefix}/institutions/${id}`;
        return this.init().get(url);
    }
    
    deleteInstitutionById = (id) => {
        const url = `${api_prefix}/institutions/${id}`;
        return this.init().delete(url);
    }

    getServiceGroups = () => {
        const url = `${api_prefix}/service_groups`;
        return this.init().get(url);
    }

    updateSchedules = (id, data) => {
        const url = `${api_prefix}/institutions/${id}/schedule`;
        return this.init().patch(url, data);
    }

    getInstitutionDiffs = (institutionLegalId) => {
        const url = `${api_prefix}/bs_institutions/${institutionLegalId}`;
        return this.init().get(url);
    }

    markInstitutionDiffsAsResolved = (institutionLegalId) => {
        const url = `${api_prefix}/bs_institutions/${institutionLegalId}/resolved_diff` ;
        const data = {}
        return this.init().post(url, data);
    }

    publishData = () => {
        const url = `${api_prefix}/publish`;
        return this.init().post(url);
    }

    createAddress = (institution_id, data) => {
        const url = `${api_prefix}/institutions/${institution_id}/addresses`;
        return this.init().post(url, data);
    }

    updateAddress = (institution_id, address_id, data) => {
        const url = `${api_prefix}/institutions/${institution_id}/addresses/${address_id}`;
        return this.init().patch(url, data);
    }

    deleteAddress = (institution_id, address_id) => {
        const url = `${api_prefix}/institutions/${institution_id}/addresses/${address_id}`;
        return this.init().delete(url);
    }

    getProfile = () => {
        const url = `${api_prefix}/profile`;
        return this.init().get(url);
    }
}
