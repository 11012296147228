import React from 'react'
import {useForm} from 'react-hook-form'

let AddressForm = props => {
    const {register, handleSubmit, errors} = useForm();
    const {address, onSubmit, onCancel }= props;

    let textInput = (name, label, defaultValue, required) => {
        const labelName = required ? `${label} *` : label;
        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">{labelName}</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input name={name} ref={register({ required: required })}  type="text" className="input" defaultValue={defaultValue} />
                            {required && errors[name] && <span className="form-error">Ce champs est requis.</span>}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    let radioInput = (name, label, defaultValue) => {
        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label" labelfor={name}>{label}</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input className="is-checkradio" id={`${name}_1`} name={name} type="radio" value="true" defaultChecked={defaultValue === true} ref={register} />
                            <label htmlFor={`${name}_1`}>Vrai</label>

                            <input className="is-checkradio" id={`${name}_2`} name={name} type="radio" value="false" defaultChecked={defaultValue === false} ref={register} />
                            <label htmlFor={`${name}_2`}>Faux</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="title is-3">Rue</h3>
            { textInput('address_line_1.fr', 'Français', address.address_line_1.fr, true) }
            { textInput('address_line_1.nl', 'Dutch', address.address_line_1.nl, true) }
            { textInput('address_line_1.en', 'English', address.address_line_1.en, true) }

            { textInput('street_number', 'Numéro', address.street_number, false) }

            <h3 className="title is-3">Ville</h3>
            { textInput('postal_code', 'Code postal', address.postal_code, true) }
            { textInput('city.fr', 'Français', address.city.fr, true) }
            { textInput('city.nl', 'Dutch', address.city.nl, true) }
            { textInput('city.en', 'English', address.city.en, true) }

            <h3 className="title is-3">Pays</h3>
            { textInput('country', 'Pays', address.country) }
            { textInput('country_code', 'Code iso du pays', address.country_code) }

            { radioInput( 'is_default', 'Adresse principale', address.is_default) }

            <input name='id' ref={register({ required: false })}  type="hidden" defaultValue={address.id} />


            <div className="section columns is-centered">
                <div className="columns is-12">
                    <div className="control">
                        <input type="submit" className="button is-primary" value="Sauver" />
                    </div>
                    <div className="control">
                        <a onClick={ () => onCancel()}>
                            &nbsp;Annuler
                        </a>
                    </div>
                </div>
            </div>

        </form>
    )
};

export { AddressForm };
