import React, { Component } from 'react';
import { Link, withRouter} from 'react-router-dom';

import { MessageForm } from './MessageForm';
import {firebaseDb} from "../../config/firebase/firebase";
import Api from '../shared/api';

const messageTemplate = {
    title: {
        fr: "",
        nl: "",
        en: ""
    },
    body: {
        fr: "",
        nl: "",
        en: ""
    },
    institutionId: null
};

const api = new Api();
class NewMessage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            institutions: []
        };
    }

    componentDidMount(){
        this.loadInstitutions();
    }

    async loadInstitutions() {
        try {
            const { data } = await api.getInstitutions({page: 1, per_page: 2000});
            const { institutions } = data;
            this.setState({
                institutions: institutions.sort( (a,b) => {return a.name.fr.localeCompare(b.name.fr)}),
            })
        } catch (e) {
            console.log(e);
        }
    }


    submit = async (values) => {
        let message = Object.assign({}, messageTemplate);
        message = this._updateMessageText("title", values, message);
        message = this._updateMessageText("body", values, message);
        message.institutionId = values.institutionId;
        message.createdAt = Date.now();
        await this.createMessage(message);
    }

    createMessage = async (message) => {
    try {
        await api.publishMessage(message)
        this.props.history.push(`/messages`);
    } catch (e) {
        console.log(e);
    }
    }

    _updateMessageText = (attributeName, values, message) => {
        message[attributeName].fr = values[`${attributeName}Fr`];
        message[attributeName].nl = values[`${attributeName}Nl`];
        message[attributeName].en = values[`${attributeName}En`];
        return message;
    };

    render() {

        const { institutions } = this.state;

        return (
            <div className="section">
                <div className="columns">
                    <div className="column is-11">
                        <h1 className="title is-size-2">
                            <Link className="icon subtitle-left-icon" to="/institutions">
                                <i className="fa fa-angle-left"></i>
                            </Link>
                            Nouveau message
                        </h1>
                    </div>
                </div>
                <div>
                    <MessageForm message={Object.assign({}, messageTemplate)} institutions={institutions} onSubmit={ this.submit } cancelURL="/messages" />
                </div>
            </div>
        )
    }
}


export default withRouter(NewMessage);
