import React, { Component } from "react"
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const GoogleMapComponent = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDUK-PQbckjOcgqll-xhAoeBI2OyyXNbis&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `300px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={12}
        defaultCenter={{ lat: props.center.lat, lng: props.center.lng }}
    >
        {props.isMarkerShown && props.markers.length > 0 &&
            props.markers.map( (marker, index) => {
                return <Marker
                    position={{ lat: marker.lat, lng: marker.lng }}
                    key={index}
                    draggable={ props.draggable }
                    onDragEnd={ props.onDragEnd }/>})
        }
    </GoogleMap>
)

class Map extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isMarkerShown: false,
        };

        this.onDragEnd = this.onDragEnd.bind(this)
    }

    onDragEnd(event){
        const latLng = event.latLng;
        const lat = latLng.lat();
        const lng = latLng.lng();

        if (this.props.onMarkerMoved !== undefined){
            this.props.onMarkerMoved(lat, lng);
        }
    }

    componentDidMount() {
        this.delayedShowMarker();
    }

    delayedShowMarker = () => {
        setTimeout(() => {
            this.setState({ isMarkerShown: true })
        }, 1000)
    };

    render() {
        let markerDraggable = false
        if (this.props.markerDraggable !== undefined) {
            markerDraggable = this.props.markerDraggable;
        }
        return (
            <GoogleMapComponent
                isMarkerShown={ this.state.isMarkerShown }
                center={ this.props.center }
                markers={ this.props.markers }
                draggable={ markerDraggable }
                onDragEnd={ this.onDragEnd }
            />
        )
    }
}
export { Map }
