import React from "react";
import { Link } from "react-router-dom";

const PublicNavbar = () => (
  <>
    <div className="navbar-start" />
    <div className="navbar-end">
      <Link className="navbar-item button is-outlined is-pulled-right is-link" to="/login">
        <i className="fa fa-sign-in" />
      </Link>
    </div>
  </>
);

export default PublicNavbar;
