import React, { Component } from "react";
import { Link } from "react-router-dom";
import Simplert from "react-simplert";

import { withServices } from "../../shared/services_provider";
import { withServiceGroups } from "../../shared/service_groups_provider";

import { InstitutionForm } from "./InstitutionForm";

import {
  institution_addictions,
  institution_accessibilities,
  institution_prices,
  institution_languages,
} from "../shared/institution_attributes";

import Api from "../shared/api";
import EmptyState from "../shared/empty_state";

class EditInstitution extends Component {
  constructor(props) {
    super(props);

    this.state = {
      institutionId: this.props.computedMatch.params.id,
      institution: null,
      services: props.services || [],
      serviceGroups: props.serviceGroups === null ? [] : props.serviceGroups,

      showDeleteAlert: false,
    };

    this.removeInstitution = this.removeInstitution.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.services !== null &&
      nextProps.services !== this.state.services
    ) {
      //Perform some operation
      this.setState({ services: nextProps.services });
    }

    if (
      nextProps.serviceGroups !== null &&
      nextProps.serviceGroups !== this.state.serviceGroups
    ) {
      //Perform some operation
      this.setState({ serviceGroups: nextProps.serviceGroups });
    }
  }

  async loadData() {
    const api = new Api();
    const {
      data: { institution },
    } = await api.getInstitutionById(this.state.institutionId);
    this.setState({ institution });
  }

  showAlert() {
    this.setState({ showDeleteAlert: true });
  }

  hideAlert() {
    this.setState({ showDeleteAlert: false });
  }

  _updateInstitutionText = (attributeName, values, institution) => {
    institution[attributeName].fr = values[`${attributeName}Fr`];
    institution[attributeName].nl = values[`${attributeName}Nl`];
    institution[attributeName].en = values[`${attributeName}En`];
    return institution;
  };

  _updateInstitutionServices = (values, institution) => {
    institution.services = [];
    let serviceKeys = Object.keys(values);
    serviceKeys.map((key) => {
      let attributes = key.split("_");
      let attributeName = attributes[0];
      if (attributeName !== "serviceId") {
        return null;
      }

      let value = values[key];
      if (value === true) {
        let serviceId = parseInt(attributes[1]); // serviceId_1
        institution.services.push(serviceId);
        return serviceId;
      }
      return null;
    });

    return institution;
  };

  updateInstitutionAttributes = (values, institution, keys) => {
    let selectedValues = [];
    keys.map((key) => {
      let value = values[key];
      if (value === true) {
        let ref = key.split("_").splice(1).join("_"); // serviceId_1
        selectedValues.push(ref);
      }
    });
    return selectedValues;
  };

  updateInstitutionAddictions = (values, institution) => {
    institution.addictions = [];
    let keys = institution_addictions.map((a) => {
      return `addictionsRef_${a.reference}`;
    });
    institution.addictions = this.updateInstitutionAttributes(
      values,
      institution,
      keys
    );
    return institution;
  };

  updateInstitutionPrices = (values, institution) => {
    let keys = institution_prices.map((p) => {
      return `pricesRef_${p.reference}`;
    });
    institution.prices = this.updateInstitutionAttributes(
      values,
      institution,
      keys
    );
    return institution;
  };

  updateInstitutionAccessibilities = (values, institution) => {
    let keys = institution_accessibilities.map((a) => {
      return `accessibilitiesRef_${a.reference}`;
    });
    institution.accessibilities = this.updateInstitutionAttributes(
      values,
      institution,
      keys
    );
    return institution;
  };

  updateInstitutionLanguages = (values, institution) => {
    institution.languages = [];
    institution_languages.map((language) => {
      if (values[`languages${language.reference}`]) {
        institution.languages.push(language.reference);
      }
    });
    return institution;
  };

  submit = async (values) => {
    try {
      const { institution } = this.state;
      const {
        main_language,
        phone,
        web,
        legal_id,
        hospital,
        prm,
        cpas,
        name_fr,
        name_en,
        name_nl,
        description_fr,
        description_en,
        description_nl,
      } = values;

      let institutionToSend = {
        main_language,
        phone,
        web,
        legal_id,
        hospital: hospital,
        prm: prm,
        cpas: cpas,
        name: {
          fr: name_fr,
          en: name_en,
          nl: name_nl,
        },
        description: {
          fr: description_fr,
          en: description_en,
          nl: description_nl,
        },
      };
      institutionToSend = this.updateInstitutionLanguages( values, institutionToSend );
      institutionToSend = this._updateInstitutionServices( values, institutionToSend );
      institutionToSend = this.updateInstitutionAddictions( values, institutionToSend );
      institutionToSend = this.updateInstitutionAccessibilities( values, institutionToSend );
      institutionToSend = this.updateInstitutionPrices( values, institutionToSend );

      const api = new Api();
      await api.updateInstitution(institution.id, institutionToSend);
      this.props.history.push(`/institutions/${institution.id}`);
    } catch (e) {
      console.log(e);
      console.log("it failed");
    }
  };

  cancelURL = () => {
    return `/institutions/${this.state.institutionId}`;
  };

  removeInstitution = () => {
    this.props.deleteInstitution(this.state.placeId, this.state.placeRecord);
    this.props.history.push("/institutions");
  };

  render() {
    const { institution } = this.state;

    if (institution === null) {
      return ( <EmptyState /> )
    }

    return (
      <div className="section">
        <div className="columns">
          <div className="column is-11">
            <h1 className="title is-size-2">
              <Link
                className="icon subtitle-left-icon"
                to={`/institutions/${this.state.institutionId}`}
              >
                <i className="fa fa-angle-left" />
              </Link>
              {institution.name.fr}
            </h1>
          </div>
        </div>
        <div className="columns">
          <div className="column is-11">
            <InstitutionForm
              institution={institution}
              addictions={institution_addictions}
              accessibilities={institution_accessibilities}
              prices={institution_prices}
              languages={institution_languages}
              services={this.state.serviceGroups}
              onSubmit={this.submit}
              cancelURL={this.cancelURL()}
            />
          </div>
        </div>
        <h3 className="title is-3">Danger</h3>
        <div className="columns is-centered">
          <div className="column is-narrow">
            <button className="button is-danger" onClick={this.showAlert}>
              Effacer l'institution
            </button>

            <Simplert
              showSimplert={this.state.showDeleteAlert}
              type="warning"
              title="Attention"
              message="Vous êtes sur le point d'effacer l'institution. Êtes-vous certain?"
              useConfirmBtn={true}
              customConfirmBtnText="Je confirme"
              customCloseBtnText="Annuler"
              onConfirm={this.removeInstitution}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withServiceGroups(withServices(EditInstitution));
