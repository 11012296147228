import React  from 'react'
import { Link } from 'react-router-dom'

import './InstitutionCard.css'

const InstitutionCard = ({ institution, services, locale }) => {

    let renderAddress = () => {
        let phoneField = `Tél: ${institution.phone}`;

        if (institution.main_address === undefined) {
            return (
            <div className="content">
                <br/>
                <br />
                <a href={phoneField}>
                    { phoneField }
                </a>
            </div>
            )
        }

        const address = institution.main_address;

        let address1 = "";
        let address2 = "";

        if (!!address) {
            address1 = address.address_line_1 ? `${address.address_line_1[locale]} ${address.street_number}` : '';
            address2 = address.city ? `${address.postal_code}, ${address.city[locale]}` : `${address.postal_code}`;
        }


        return (
            <div className="content">
                { address1 }
                <br/>
                { address2 }
                <br />
                <a href={phoneField}>
                    { phoneField }
                </a>
            </div>
        )
    };



    const institutionServiceRefs = institution.services ? institution.services : [];
    const selectedServices = services.filter( (service) => { return institutionServiceRefs.includes(service.reference) } );

    return (
        <div className="card">
            <div className="card-image relative-position">
                <figure className="background-card-custom is-size-5 has-text-white has-text-centered is-uppercase has-text-weight-light">
                    { institution.name && institution.name.fr }
                </figure>
                <Link className="edit-container" to={ `/institutions/${institution.id}`}>
                    <span className="icon">
                        <i className="fa fa-eye"></i>
                    </span>
                </Link>
            </div>
            <div className="card-content">
                <div className="media is-minimum-height-32 icon-medium space-medium">
                    { selectedServices.length > 0 && selectedServices.map(function(service){
                        return (
                            <span key={service.id} className={`dune-${service.reference}`}/>
                        )
                    })}
                </div>

                { renderAddress() }
                { institution.news && (
                    <div className="card-attention">
                        <img src="images/attention.png" alt="News" width="32" height="32"/>
                    </div>
                )}
            </div>
        </div>
    );
};

export { InstitutionCard }
