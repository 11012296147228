import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Simplert from 'react-simplert';
import Geocode from "react-geocode";

import {TableAddress} from './TableAddress';
import {AddressForm} from './AddressForm';

import { enrichLocationWithStibStops, prepareStibStops } from '../../shared/geoStib';
import { enrichLocationWithTecStops, prepareTecStops } from '../../shared/geoTec';

import Api from '../shared/api';
import EmptyState from '../shared/empty_state';

import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '10%',
        left                  : '20%',
        right                 : '20%',
        bottom                : '10%',
    }
};
Modal.setAppElement('#root');

Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API_KEY);
Geocode.setLanguage("fr");
Geocode.enableDebug();

const api = new Api();

class EditAddresses extends Component {

    constructor(props) {
        super(props);

        this.state = {
            institutionId: this.props.computedMatch.params.id,
            institution: null,
            showDeleteAlert: false,
            modalIsOpen: false,
            editableAddress: null,
            showError: false,
            stibStops: prepareStibStops(),
            tecStops: prepareTecStops()
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.editNewAddress = this.editNewAddress.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }


    async loadData() {
        const api = new Api()
        const { data: { institution } } = await api.getInstitutionById(this.state.institutionId);
        if (!institution.addresses) { institution.addresses = []; }
        if (!institution.locations) { institution.locations = []; }

        this.setState({institution});
    }

    openModal(address) {
        if (! this.isBoolean(address.is_default)) {
            address.is_default = JSON.parse(address.is_default.toLowerCase());
        }

        this.setState({modalIsOpen: true, editableAddress: address});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    closeModal() {
        if (! this.state.modalIsOpen) { return }
        this.setState({modalIsOpen: false});
    }

    showAlert() {
        this.setState({showDeleteAlert: true});
    }

    hideAlert() {
        this.setState({showDeleteAlert: false});
    }

    isBoolean = val => 'boolean' === typeof val;

    _newAddressObject = () => {
        let {institution} = this.state;
        const addresses = institution.addresses;
        let address = {
              address_line_1: { fr: null, en: null, nl: null },
              city: { fr: null, en: null, nl: null },
              street_number: null,
              postal_code: null,
              country: "Belgique",
              country_code: "BE",
              id: undefined,
              is_default: addresses.length === 0
          };
          return address;
    };

    editNewAddress = () => {
        let newAddress = this._newAddressObject();
        let {institution} = this.state;
        this.setState(institution);
        this.openModal(newAddress);
    };


    submit = async (values) => {
        this.setState({showError: false});

        let {institution} = this.state;
        let address = this.state.editableAddress;

        // Map data
        address.street_number = Number(values.street_number);
        address.postal_code = values.postal_code;
        address.country = values.country;
        address.country_code = values.country_code;

        // Ensure that at least one is default
        address.is_default = values.is_default === "true";

        address.address_line_1 = values.address_line_1;
        address.city = values.city;

        // retrieve location
        let updatedLocation = await this._retrieveGeoCoding(address);
      
        if ( updatedLocation === {} ) {
            this.setState({showError: true});
        }

        address.latitude = updatedLocation.latitude;
        address.longitude = updatedLocation.longitude;
        address.google_place_id = updatedLocation.googlePlaceId;
        address.google_place = updatedLocation.googlePlace;
        address.google_formatted_address = updatedLocation.googleFormattedAddress;

        address = this._enrichAddressWithStops(address, updatedLocation)

        if(address.id) {
            await api.updateAddress(institution.id, address.id, address)
        } else {
            let response = await api.createAddress(institution.id, address)
            let addressRecord = JSON.parse(response.data.address);
            
            if (institution.addresses) {
                institution.addresses.push(addressRecord);
            } else {
                institution.addresses = [addressRecord];
            }
        }

        this.closeModal();
    };


    _enrichAddressWithStops = (address, updatedLocation) => {

        address.stops = [];

        address = this._enrichAddressWithStibStops(address, updatedLocation);
        address = this._enrichAddressWithTecStops(address, updatedLocation);
        return address;
    };

    _enrichAddressWithStibStops = (address, updatedLocation) => {
        let newStop = enrichLocationWithStibStops(updatedLocation, this.state.stibStops);

        address.stops.push(newStop);
        return address;
    };

    _enrichAddressWithTecStops = (address, updatedLocation) => {
        let newStop = enrichLocationWithTecStops(updatedLocation, this.state.tecStops);

        address.stops.push(newStop);
        return address;
    };

    _retrieveGeoCoding = async (address) => {
        let addressString = `${address.address_line_1.fr} ${address.street_number}, ${address.postal_code} ${address.city.fr}`;
        Geocode.setRegion(address.country_code);
        let geocodingResponse = undefined;

        try {
            geocodingResponse = await Geocode.fromAddress(addressString);
            const { lat, lng } = geocodingResponse.results[0].geometry.location;
            const googlePlace = geocodingResponse.results[0];

            return {
                latitude: lat,
                longitude: lng,
                googlePlaceId: googlePlace.place_id,
                googlePlace: googlePlace,
                googleFormattedAddress: googlePlace.formatted_address
            }

        } catch (e) {
            return {};
        } 
    };

    _askToDeleteAddress = (address) => {
        this.setState({editableAddress: address, showDeleteAlert: true});
    };

    _removeAddress = async () => {
        let {institution, editableAddress} = this.state;
        await api.deleteAddress(institution.id, editableAddress.id)
        this.setState({
            showDeleteAlert: false,
            editableAddress: null,
            institution: {
                ...institution,
                addresses: institution.addresses.filter(address => address.id !== editableAddress.id)
            }
        });
    };

    render(){
        const {institution} = this.state;

        if (institution === null) {
            return (
                <EmptyState />
            )
        }

        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    style={customStyles}
                    contentLabel="Modification adresse"
                >
                    <h1 className='title is-size-2'>Formulaire d'édition</h1>
                    { this.state.showError && <div class="notification is-danger">
                        L'adresse est invalide et n'a pas pu être géolocalisée.
                    </div> }
                    <AddressForm address={this.state.editableAddress} onSubmit={this.submit} onCancel={this.closeModal} />
                </Modal>

                <section className="section">
                    <div className="columns">
                        <div className="column is-10">
                            <h1 className="title is-size-2">
                                <Link className="icon subtitle-left-icon" to={ `/institutions/${this.state.institutionId}` }>
                                    <i className="fa fa-angle-left"/>
                                </Link>
                                {institution.name.fr}
                            </h1>
                        </div>
                        <div className="column is-2">
                            <a className="button is-link" onClick={ () => this.editNewAddress()}>
                                <span className="icon is-small">
                              <i className="fa fa-plus edition-left-icon"></i>
                            </span>
                                &nbsp;Nouvelle addresse
                            </a>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <h2 className="subtitle is-size-3">Adresses</h2>
                    <TableAddress addresses={institution.addresses} onEdit={this.openModal} onDelete={this._askToDeleteAddress} />

                </section>

                <Simplert
                    showSimplert={this.state.showDeleteAlert}
                    type="warning"
                    title="Attention"
                    message="Vous êtes sur le point d'effacer une addresse. Êtes-vous certain?"
                    useConfirmBtn={true}
                    customConfirmBtnText="Je confirme"
                    customCloseBtnText="Annuler"
                    onConfirm={this._removeAddress}
                />
            </div>
        )
    }
}

export { EditAddresses };
