const EMPTY_INSTITUTION = {
  "addresses" : [],
  "cpas" : false,
  "prm" : false,
  "description" : {
      "en" : "",
      "fr" : "",
      "nl" : ""
  },
  "hospital" : false,
  "id" : 0,
  "languages" : [ "fr" ],
  "locations" : [],
  "name" : {
      "en" : "",
      "fr" : "",
      "nl" : ""
  },
  "phone" : "",
  "schedules" : [],
  "services" : [],
  "stops" : [],
  "summarySchedule" : [],
  "web" : ""
};

export { EMPTY_INSTITUTION }