import {DAYSMAPPINGS} from "../../shared/constants";
import React from "react";


let TableSchedule = props => {

    const { schedule, locale } = props;

    if (schedule === null || schedule === undefined) {
        return (
            <table className="table is-striped is-hoverable is-fullwidth">
                <thead>
                <tr>
                    <th width="">Jour</th>
                    <th width="">Heures</th>
                    <th width="">Description</th>
                </tr>
                </thead>
                <tbody>
                { DAYSMAPPINGS.map((day, dayIndex) => {  
                    return (<tr key={dayIndex}>
                            <td>{ day }</td>
                            <td>
                                <div></div>
                            </td>
                            <td></td>
                        </tr>
                    )})
                }
                </tbody>
    
            </table>
        )
    }

    let isClosed = (daySchedule) => {
        if (daySchedule === undefined) return true;
        if (daySchedule.allDayOpen || daySchedule.variable) return false;
        return  [undefined, '', null].includes(daySchedule.from) || [undefined, '', null].includes(daySchedule.to);
    };

    let hasPause = (daySchedule) => {
        return daySchedule.closeFrom !== '' && daySchedule.closeTo !== '';
    };

    let _renderPause = (daySchedule) => {
        return (
            <ul>
                <li>Ouvert de {daySchedule.from} à {daySchedule.closeFrom}</li>
                <li>Ouvert de {daySchedule.closeTo} à {daySchedule.to}</li>
            </ul>
        );
    };

    let getScheduleHourDescription = (daySchedule) => {

        if (daySchedule.allDayOpen ) { return "Ouvert 24h/24" }
        if ( daySchedule.variable ) { return "Horaires variables" }

        if (hasPause(daySchedule)) { return _renderPause(daySchedule) }
        return `Ouvert de ${daySchedule.from} à ${daySchedule.to}`;
    };

    return (
        <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
            <tr>
                <th width="">Jour</th>
                <th width="">Heures</th>
                <th width="">Description</th>
            </tr>
            </thead>
            <tbody>
            { DAYSMAPPINGS.map((day, dayIndex) => {
                const daySchedule = schedule.openingHours ? schedule.openingHours.filter( (oh) => { return oh.day === dayIndex })[0] : undefined;
                const description = (daySchedule === undefined) ? '' : daySchedule.description[locale];
                if (isClosed(daySchedule)) { return (
                    <tr key={dayIndex}>
                        <td>{ day }</td>
                        <td> Fermé </td>
                        <td> { description } </td>
                    </tr>
                ) }

                return (<tr key={dayIndex}>
                        <td>{ day }</td>
                        <td>
                            <div> { getScheduleHourDescription(daySchedule) } </div>
                        </td>
                        <td> { description } </td>
                    </tr>
                )})
            }
            </tbody>

        </table>
    )
};

export { TableSchedule };
