import React from "react";

//import shortId from 'shortid';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';

import { firebaseStorageRef, firebaseStorageTaskEvent } from '../../config/firebase/firebase';

// And import the necessary css
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// register the filepond plugins for additional functionality
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginImageResize, FilePondPluginImageCrop);


export function ImageUpload({
                                imagePath,
                                onRequestSave,
                                onRequestClear,
                                defaultFiles = [],
                            }) {
    // use a useState hook to maintain our files collection
    const [files, setFiles] = React.useState(defaultFiles);

    const server = {
        // this uploads the image using firebase
        process: (fieldName, file, metadata, load, error, progress, abort) => {
            // create a unique id for the file
            // const id = shortId.generate();

            // upload the image to firebase
            const task = firebaseStorageRef.child(imagePath).put(file, {
                contentType: 'image/jpeg',
            });

            // monitor the task to provide updates to FilePond
            task.on(
                firebaseStorageTaskEvent.STATE_CHANGED,
                snap => {
                    // provide progress updates
                    progress(true, snap.bytesTransferred, snap.totalBytes)
                },
                err => {
                    // provide errors
                    error(err.message)
                },
                 () => {
                    // the file has been uploaded
                    load(imagePath);
                    onRequestSave(imagePath);
                }
            )
        },

        // this loads an already uploaded image to firebase
        load: (source, load, error, progress, abort) => {
            // reset our progress
            progress(true, 0, 1024);

            // fetch the download URL from firebase
            firebaseStorageRef
                .child(source)
                .getDownloadURL()
                .then(url => {
                    // fetch the actual image using the download URL
                    // and provide the blob to FilePond using the load callback
                    let xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = function(event) {
                        let blob = xhr.response;
                        load(blob)
                    };
                    xhr.open('GET', url);
                    xhr.send();
                })
                .catch(err => {
                    error(err.message);
                    abort();
                })
        },
    };

    return (
        <FilePond
            files={files}
            allowMultiple={false}
            maxFiles={1}
            allowImageResize={true}
            imageResizeTargetHeight={674}
            allowImageCrop={true}
            imageCropAspectRatio="16:10"
            onupdatefiles={fileItems => {
                if (fileItems.length === 0) {
                    onRequestClear()
                }

                setFiles(fileItems.map(fileItem => fileItem.file))
            }}
            server={server}
        />
    )
}



