import React, { Component } from "react";
import { Link } from 'react-router-dom';

import {firebaseDb, firebaseStorageRef} from '../../config/firebase/firebase';
import EmptyState from "../shared/empty_state";


import { ImageUpload } from '../shared/image_uploader';


class InstitutionHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            institutionId: props.computedMatch.params.id,
            institution: null,
        };
        this._onImageSave = this._onImageSave.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }

    loadData() {
        let institutionsRef = firebaseDb.ref(`institutions/${this.state.institutionId}`);
        institutionsRef.on('value', snapshot => {
            const institution = snapshot.val();
            //console.log('institution', institution);

            this.setState({institution});
        });
    }

    _onRequestClear() {
        console.log('TO DO');
    }

    async _onImageSave(imagePath) {
        const { institution } = this.state;
        const imageHeaderURL  = await firebaseStorageRef.child(imagePath).getDownloadURL();
        institution.imageHeader = imageHeaderURL;

        // const institutionRequest =
        await firebaseDb.ref('institutions').child(institution.id).set(institution);
    }

    render() {
        const {institution} = this.state;

        if (!institution) {
            return (
                <EmptyState />
            )
        }

        return (
            <div>
                <section className="section">
                    <div className="columns">
                        <div className="column is-8">
                            <h1 className="title is-size-2">
                                <Link className="icon subtitle-left-icon" to={`/institutions/${institution.id}`}>
                                    <i className="fa fa-angle-left"/>
                                </Link>
                                {institution.name['fr']}
                            </h1>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <p>La résolution des images doit être de 1920 x 672 ou 1440 x 504. Privilégiez ce format pour assurer un bon résultat.</p>
                    <ImageUpload onRequestSave={this._onImageSave} onRequestClear={ this._onRequestClear} imagePath={`/images/${institution.id}`} />
                </section>
            </div>
        )
    }


}

export { InstitutionHeader };
