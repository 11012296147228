import React from 'react'
import { RouteCard } from './RouteCard';

let AddressStop = ({ addressStop, locale }) => {
    const { id, name, routes } = addressStop;
    
    let stopDetail = ({id, name, routes}) => {
        return (
            <div>
                <h4>{ name[locale] }</h4>
                {/* eslint-disable-next-line array-callback-return */}
                { routes.map( function(route, index){
                    return ( <RouteCard route={route} keyIndex={ `stop_${id}_${index}` }/> )
                })}
            </div>
        )
    };

    return (
        <div>
            {stopDetail({id, name, routes})}
        </div>
    )
};

export { AddressStop }
