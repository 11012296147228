import React, { useState, useEffect, useContext } from "react";
import Api from "../components/shared/api";

const ServicesContext = React.createContext({});

const ServicesProvider = ({ children }) => {
    const [services, setServices] = useState([]);
    const [serviceGroups, setServiceGroups] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const getServices = async () => {
            try {
                const api = new Api();
                const {data: { service_groups }} = await api.getServiceGroups();
                const extractedServices = service_groups.map(({services}) => services).flat()
                setServiceGroups(service_groups)
                setServices(extractedServices)
                setIsLoading(false)
            } catch(e) {
                setIsLoading(false)
            }
        }

        getServices();
    }, [])

    if(isLoading) {
        return <p>Loading</p>
    }

    return (
        <ServicesContext.Provider value={{services, serviceGroups}}>
            {children}
        </ServicesContext.Provider>
    );
};

const withServices = (Component) =>
    (props) => {
        const { services, serviceGroups } = useContext(ServicesContext);
        return (<Component {...props} {...{services, serviceGroups}} />)
    };

export { ServicesContext, ServicesProvider, withServices };
