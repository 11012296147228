import React, { Component } from "react";

import {withServices} from "../../shared/services_provider";
import { InstitutionCard } from './InstitutionCard';
import { Link, withRouter } from "react-router-dom";
import Api from '../shared/api';
import { toast } from "react-toastify";
import EmptyState from "../shared/empty_state";

const api = new Api();

class Institutions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            services: props.services || [],
            allInstitutions: [],
            institutions: [],
            totalItems: 0,
            displayedItems: 20,
            itemsIncrement: 20,
            searchQuery: '',
            locale: 'fr',
            metadata: null,
        };

        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.publishData = this.publishData.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.services !== null && (nextProps.services !== this.props.services)){
            this.setState({services: nextProps.services });
        }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
          this.setState({
              institutions: [],
              allInstitutions: [],
              metadata: null
          }, () => this.loadData())
          window.scrollTo(0, 0);
        });
    }

      componentWillUnmount() {
          this.unlisten();
      }


    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        try {
            const page = urlParams.get('page') || 1
            const per_page = urlParams.get('per_page') || 25
            const { data } = await api.getInstitutions({page, per_page});
            const { institutions, metadata } = data;
            this.setState({
                institutions: institutions,
                allInstitutions: institutions,
                totalItems: metadata.total_records,
                metadata: metadata,
            })
        } catch (e) {
            console.log(e);
        }
    }

    _filterInstitutions(searchQuery) {
        if (searchQuery === '') {
            return this.state.allInstitutions;
        }

        const queryRegEx = new RegExp(`.*${searchQuery}.*`, 'i');
        return this.state.allInstitutions.filter((institution) => { return queryRegEx.test(institution.name.fr + institution.name.en + institution.name.nl) });
    }


    handleSearchChange(event) {
        let searchQuery =  event.target.value;
        let {filter} = this.state; 
        let institutions = this._filterInstitutions(searchQuery);
        if (filter) {
            institutions = institutions.filter((institution) => { return institution.news});
        } 
        this.setState({institutions, searchQuery });
    }


    handleFilterChange(event) {
        let filter =  event.target.checked;
        let {searchQuery} = this.state; 
        let institutions = this._filterInstitutions(searchQuery);
        if (filter) {
            institutions = institutions.filter((institution) => { return institution.news});
        }         
   
        this.setState({institutions, filter });

    }

    handleSearchSubmit(event){
        event.preventDefault();
    }

    async publishData() {
        await toast.promise(api.publishData(),
        {
            pending: 'On publie les données',
            success: "Données publiées avec succès",
            error: "Un soucis est arrivé lors de la publication des données"
        })
    }

    _dataPublished(){
        console.log('FileGenerated');
    }
    _publicationError(errorMessage){
        console.log('errorMessage', errorMessage);
    }


    render() {
        const { allInstitutions, institutions, services, locale, metadata } = this.state;

        if (allInstitutions.length === 0) return (<EmptyState />)

        return (
            <section className="section">
                <div className="columns">
                    <div className="column is-10">
                        <h1 className="title is-size-2">Institutions</h1>
                    </div>
                    <div className="column is-1">
                        <button className="button is-outlined is-pulled-right is-link" onClick={ (e) => this.publishData() }>
                            <span className="icon is-small">
                              <i className="fa fa-upload edition-left-icon"></i>
                            </span>
                            &nbsp;Publication des données
                        </button>
                    </div>

                    <div className="column is-1">
                        <Link className="button is-outlined is-pulled-right is-link" to="/institutions/new">
                            <span className="icon is-small">
                              <i className="fa fa-plus edition-left-icon"></i>
                            </span>
                            &nbsp;Nouveau
                        </Link>
                    </div>
                </div>


                <form className="columns">
                    <div className="column is-8">
                        <div className="field">
                            <p className="control">
                                <input className="input" type="text" placeholder="Recherche pas nom" value={this.state.searchQuery} onChange={this.handleSearchChange} />
                            </p>
                        </div>
                    </div>
                    <div className="column is-4">
                        <label className="checkbox">
                            <input type="checkbox"  onChange={this.handleFilterChange}/>
                            Afficher uniquement les mises à jour
                        </label>
                    </div>
                </form>

                <div className="columns is-multiline">

                    { institutions != null && institutions.map(function(institution, index){
                        return (
                            <div className="column is-one-quarter" key={ index } >
                                <InstitutionCard  institution={ institution } services={ services } locale={ locale }/>
                            </div>
                        )
                    })}
                </div>
                {metadata && (<nav className="pagination" role="navigation" aria-label="pagination">
                    {!metadata.first_page && (
                        <Link
                            className="pagination-previous"
                            to={ `/institutions?page=${metadata.current_page - 1}`}
                        >
                            Page précédente
                        </Link>
                    )}
                    {!metadata.last_page && (
                        <Link
                            className="pagination-previous"
                            to={ `/institutions?page=${metadata.current_page + 1}`}
                        >
                            Page suivante
                        </Link>
                    )}
                    <ul className="pagination-list">
                        {[...Array(metadata.total_pages).keys()].map(page => (
                            <li>
                                {page + 1 === metadata.current_page ? (
                                    <Link
                                        className="pagination-link is-current"
                                        to={ `/institutions?page=${page + 1}`}
                                        aria-label={`Goto page ${page + 1}`}
                                        aria-current="page"
                                    >
                                        {page + 1}
                                    </Link>
                                ) : (
                                    <Link
                                        className="pagination-link"
                                        to={ `/institutions?page=${page + 1}`}
                                        aria-label={`Goto page ${page + 1}`}
                                    >
                                        {page + 1}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </nav>)}
            </section>

        )
    }
}


const EMPTY_INSTITUTION = {
    "addresses" : [],
    "cpas" : false,
    "description" : {
        "en" : "",
        "fr" : "",
        "nl" : ""
    },
    "hospital" : false,
    "id" : 0,
    "languages" : [ "fr" ],
    "locations" : [],
    "name" : {
        "en" : "",
        "fr" : "",
        "nl" : ""
    },
    "phone" : "",
    "schedules" : [],
    "services" : [],
    "stops" : [],
    "summarySchedule" : [],
    "web" : ""
};


export default withRouter(withServices(Institutions));
