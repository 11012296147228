import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Simplert from 'react-simplert';

import { Map } from '../googlemap/Map';

import { ServiceCard } from './ServiceCard';
import { TableSchedule } from './TableSchedule';
import LocaleSelectorForm from '../shared/locale_selector_form';

import './DetailedInstitution.css'
import {withServices} from "../../shared/services_provider";
import {AddressStop} from "./AddressStop";

import {institution_addictions, institution_accessibilities, institution_prices} from "../shared/institution_attributes";
import Api from '../shared/api';
import EmptyState from '../shared/empty_state';

class DetailedInstitution extends Component {

    constructor(props) {
        super(props);
        this.state = {
            institutionId: props.computedMatch.params.id,
            institution: null,
            mainAddress: undefined,
            services: props.services || [],
            selectedSchedule: 0,
            locale: 'fr',
            showConfirmation: false
        };

        this._changeLanguage = this._changeLanguage.bind(this);
        this._selectSchedule = this._selectSchedule.bind(this);
        this._deleteInstitution = this._deleteInstitution.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.services !== null && (nextProps.services !== this.props.services)){
            //Perform some operation
            this.setState({services: nextProps.services });
        }
    }

    async loadData() {
        const api = new Api()
        const { data: { institution } } = await api.getInstitutionById(this.state.institutionId);

        const mainAddress = institution.main_address;
    
        this.setState({institution: institution, mainAddress});
    }

    _askToDelete() {
        this.setState({showConfirmation: true});
    }

    async _deleteInstitution() {
        const api = new Api()
        await api.deleteInstitutionById(this.state.institutionId);
        this.props.history.push('/institutions');
    };

    _changeLanguage(newLocale) {
        this.setState({locale: newLocale});
    }

    _selectSchedule(scheduleIndex){
        if ( scheduleIndex === this.state.selectedSchedule) { return }
        this.setState({selectedSchedule: scheduleIndex});
    }

    render() {
        const {institution, mainAddress} = this.state;

        if (!institution) {
            return ( <EmptyState /> )
        }

        const emptyAddress = {
            address_line_1: "",
            postal_code: "",
            city: "",
            country: ""
        };
        let {address_line_1, postal_code, city, country} = mainAddress || emptyAddress;


        return (
            <div>
                <section className="section">
                    <div className="columns">
                        <div className="column is-7">
                            <h1 className="title is-size-2">
                                <Link className="icon subtitle-left-icon" to="/institutions">
                                  <i className="fa fa-angle-left"/>
                                </Link>
                                {institution.name[this.state.locale]}
                            </h1>
                        </div>
                        <div className="column is-5">
                            <div className="field is-grouped is-pulled-right">
                                <div className="control">
                                    <LocaleSelectorForm onSubmit={this._changeLanguage} selectedValue={this.state.locale} />
                                </div>

                           

                                <div className="control">
                                     <Link className="button is-outlined is-link" to={ `/institutions/${institution.id}/diff` }>
                                        <span className="icon is-small">
                                          <i className="fa fa-code"/>
                                        </span>
                                    </Link>
                                </div>


                                {/* <div className="control">
                                    <Link className="button is-outlined is-link" to={ `/institutions/${institution.id}/header` }>
                                        <span className="icon is-small">
                                          <i className="fa fa-image"/>
                                        </span>
                                    </Link>
                                </div> */}

                                <div className="control">
                                    <Link className="button is-outlined is-link" to={ `/institutions/${institution.id}/schedules` }>
                                        <span className="icon is-small">
                                          <i className="fa fa-calendar"/>
                                        </span>
                                    </Link>
                                </div>

                                <div className="control">
                                    <Link className="button is-outlined is-link" to={ `/institutions/${institution.id}/addresses` }>
                                        <span className="icon is-small">
                                          <i className="fa fa-location-arrow"/>
                                        </span>
                                    </Link>
                                </div>

                                <div className="control">
                                    <Link className="button is-outlined is-link" to={ `/institutions/${institution.id}/edit` }>
                                        <span className="icon is-small">
                                          <i className="fa fa-pencil"/>
                                        </span>
                                    </Link>
                                </div>

                                <div className="control">
                                    <a className="button is-outlined is-danger" onClick={ () => this._askToDelete()}>
                                        <span className="icon is-small">
                                          <i className="fa fa-trash"/>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    { institution.news && (
                            <div className="columns">
                            <div className="column is-12">
                                <p className="is-size-8 notice is-custom-color">
                                    Des nouvelles informations de Bruxelles Social sont disponibles.
                                    
                                    <Link to={ `/institutions/${institution.id}/diff` }>
                                        Voir les différences
                                    </Link>
                                </p> 
                            </div>
                        </div>
                    )}
                    

                    <div className="tile is-ancestor">

                        <div className="tile is-vertical is-8">

                            <div className="tile is-parent">
                                <div className="tile is-6 is-child box notification is-dark">
                                    <h2 className="subtitle is-size-3">
                                        <span className="icon subtitle-left-icon">
                                          <i className="fa fa-compass"/>
                                        </span>
                                        Adresse principale
                                    </h2>
                                    <div>Numéro d'entreprise: { institution.legal_id }</div>
                                    <div>{ address_line_1[this.state.locale] }</div>
                                    <div>{ postal_code}, { city[this.state.locale] } </div>
                                    <div>{ country }</div>
                                </div>
                                <div className="tile is-6 is-child box notification">
                                    <h2 className="subtitle is-size-3">
                                        <span className="icon subtitle-left-icon">
                                          <i className="fa fa-address-card-o"/>
                                        </span>
                                        Contact
                                    </h2>
                                    <div>
                                        <span className="icon ">
                                          <i className="fa fa-phone"/>
                                        </span>
                                        { institution.phone }
                                    </div>
                                    <div>
                                        <span className="icon ">
                                          <i className="fa fa-language"/>
                                        </span>
                                        { institution.main_language }
                                    </div>
                                    <div>
                                        <span className="icon ">
                                          <i className="fa fa-globe"/>
                                        </span>
                                        { institution.languages && institution.languages.join(' ') }
                                    </div>
                                </div>
                            </div>


                            <div className="tile is-parent">
                                <div className="tile is-child box notification is-custom-color">
                                    <h2 className="subtitle is-size-3">
                                <span className="icon subtitle-left-icon">
                                  <i className="fa fa-hand-spock-o"></i>
                                </span>
                                        Services
                                    </h2>
                                    <div className="level">
                                        {
                                            this._renderSpecialServices(institution)
                                        }
                                        {
                                            institution.services !== undefined && this._renderServices(institution)
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="tile is-parent">
                                { this._renderTranslatedContent() }
                            </div>

                            <div className="tile is-parent">
                                { this._renderAttributes() }
                            </div>

                        </div>

                        <div className="tile is-vertical is-parent is-4">
                            <div className="tile is-child">
                                <div className="box">
                                    { this._renderMap() }
                                </div>
                            </div>

                            {/* <div className="tile is-child">
                                { this._renderHeader() }
                            </div> */}

                            <div className="tile is-child">
                                { this._renderStops() }
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section">
                    <h2 className="subtitle is-size-3">Horaires</h2>
                    { this._renderTabs() }
                    { this._renderSchedule() }
                </section>


                <Simplert
                    showSimplert={this.state.showConfirmation}
                    type="warning"
                    title="Attention"
                    message="Vous êtes sur le point d'effacer une addresse. Êtes-vous certain?"
                    useConfirmBtn={true}
                    customConfirmBtnText="Je confirme"
                    customCloseBtnText="Annuler"
                    onConfirm={this._deleteInstitution}
                />

            </div>
        )
    }

    _renderSchedule(){
        let { institution, selectedSchedule, locale} = this.state;
        if (!institution.schedules) { return };
        let schedule = institution.schedules[selectedSchedule];

        return (
            <TableSchedule schedule={schedule} locale={locale} />
        )
    }

    _renderSpecialServices(institution){
        let elements = [];
        if (institution.hospital) {
            elements.push(
                (
                    <div className="level-item has-text-centered icon-big" key="hopital">
                        <span key="hopital" className="dune-hopital"/>
                    </div>
                )
            )
        }
        if (institution.cpas) {
            elements.push(
                (
                    <div className="level-item has-text-centered icon-big" key="hospital">
                        <span key="cpas" className="dune-cpas"/>
                    </div>
                )
            )
        }
        if (institution.prm) {
            elements.push(
                (
                    <div className="level-item has-text-centered icon-big" key="prm">
                        <span key="prm" className="dune-handicap"/>
                    </div>
                )
            )
        }
        return elements.map( (e) => { return e;})
    }

    _renderServices(institution) {
        let {locale, services } = this.state;
        return institution.services.map( (serviceRef) => {
            const service = services.find( service => { return service.reference == serviceRef;});
            if (service) {
                return (
                    <div className="level-item has-text-centered icon-big" key={ serviceRef }>
                        <ServiceCard service={ service } serviceName={ service.name[locale] } />
                    </div>
                )
            }

        })
    }


    _renderTabs(){
        let { institution, selectedSchedule, locale } = this.state;

        if (institution === null) { return }
        if (!institution.schedules) { return }
        if (institution.schedules.length < 1) { return }

        return (
            <div className="tabs">
                <ul>
                    {institution.schedules.map((schedule, index) => {
                        const className = ( index === selectedSchedule ) ? 'is-active' : '';
                        return (
                           <li className={className} key={index}>
                               <a onClick={ () => this._selectSchedule(index)}>
                                   {schedule.name[locale]}
                               </a>
                           </li>
                       )
                    })
                    }
                </ul>
            </div>
        )
    }

    _renderAttributes(){
        const {locale, institution} = this.state;

        return (
            <div className="tile is-parent">
                <div className="tile is-4 is-child box notification is-dark">
                    <h2 className="subtitle is-size-3">
                                        <span className="icon subtitle-left-icon">
                                          <i className="fa fa-compass"/>
                                        </span>
                        Accessibilité
                    </h2>
                    <ul>
                        { institution.accessibilities && institution.accessibilities.map( (a) => {
                            let acc = institution_accessibilities.find( el =>  {return el.reference === a} )
                            return ( <li> {acc.label.fr}</li>)
                        } )}
                    </ul>
                </div>

                <div className="tile is-4 is-child box notification">
                    <h2 className="subtitle is-size-3">
                        <span className="icon subtitle-left-icon">
                          <i className="fa fa-compass"/>
                        </span>
                        Prix
                    </h2>
                    <ul>
                        { institution.prices && institution.prices.map( (p) => {
                            let acc = institution_prices.find( el =>  {return el.reference === p} )
                            return ( <li key={p}> {acc.label.fr}</li>)
                        } )}
                    </ul>
                </div>

                <div className="tile is-4 is-child box notification is-dark">
                    <h2 className="subtitle is-size-3">
                                        <span className="icon subtitle-left-icon">
                                          <i className="fa fa-compass"/>
                                        </span>
                        Assuétudes
                    </h2>
                    <ul className="level has-text-centered icon-big">
                        { institution.addictions && institution.addictions.map( (a) => {
                            let acc = institution_addictions.find( el =>  {return el.reference === a} )
                            return ( <span className={`level-item dune-${acc.reference}`}/> )

                        } )}
                    </ul>
                </div>
            </div>
        );
    }

    _renderTranslatedContent(){
        const {locale, institution} = this.state;

        return (
            <div className="tile ">
                <div className="tile is-child box">
                    <h2 className="subtitle is-size-3">
                        <span className="icon subtitle-left-icon">
                          <i className="fa fa-puzzle-piece"/>
                        </span>
                        Description
                    </h2>
                    <div className="content translation-content">
                        <p>{ institution.description[locale] }</p>
                    </div>
                </div>
            </div>
        )
    }
    _renderMap(){
        const {mainAddress} = this.state;
        if (mainAddress === null) { return }
        const locationObject = { lat: mainAddress.latitude, lng: mainAddress.longitude };
        return (
            <Map
                defaultZoom={8}
                defaultCenter={ locationObject }

                center={ locationObject }
                markers={ [locationObject] }
            />
        )

    }

    _renderHeader() {
        let { institution } = this.state;
        const noImage = (institution.imageHeader === undefined || !institution.imageHeader.length);

        return (
            <div className="box">
                <div className="header-image-tile">
                    { !noImage && <img src={institution.imageHeader} alt="institution logo" /> }
                    { noImage && <Link className="button is-outlined is-link" to={ `/institutions/${institution.id}/header` }>
                                <span className="icon is-small">
                                  <i className="fa fa-image edition-left-icon"/>
                                </span>
                        Ajouter une image de couverture
                    </Link>
                    }
                </div>
            </div>
        )
    }

    _renderStops() {
        let { mainAddress, locale } = this.state;
        if (mainAddress === null) { return }
        let { stops } = mainAddress;

        return (
            <div className="tile">
                <div className="tile is-child box">
                    <h2 className="subtitle is-size-3">
                        <span className="icon subtitle-left-icon">
                          <i className="fa fa-puzzle-piece"/>
                        </span>
                        Stops
                    </h2>
                    <div className="content">
                        {  stops && stops.map(function(stop, index) {
                            return (<AddressStop addressStop={stop} locale={locale} key={ stop.id } />)
                        })}
                    </div>
                </div>
            </div>
        )
    }

}
export default withRouter(withServices(DetailedInstitution));
