import React from 'react'
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

let LoginForm = props => {
    const history = useHistory();
    const {loginWithRedirect, isAuthenticated } = useAuth0();
    
    if (isAuthenticated) {
        history.push('/');
        return null;
    }

    return (
        <button
            className="button is-outlined is-link is-fullwidth"
            onClick={loginWithRedirect}
        >
            Se connecter
        </button>
    )
};

export default LoginForm;
