import React from "react";
import {Link} from "react-router-dom";

let TableAddress = props => {

    const { addresses, onEdit, onDelete } = props;

    let _renderAddressLine = (address, index) => {
        return (
            <tr key={index}>
                <td> {_computeTranslatedAddress(address, 'fr')} </td>
                <td> {_computeTranslatedAddress(address, 'nl')} </td>
                <td> {_computeTranslatedAddress(address, 'en')} </td>
                <td> { address.is_default ? 'Oui' : '' } </td>
                <td>
                    <a className="button is-outlined is-link" onClick={ () => onEdit(address)}>
                        <span className="icon is-small">
                          <i className="fa fa-pencil"/>
                        </span>
                    </a>
                    &nbsp;
                    <a className="button is-outlined is-danger" onClick={ () => onDelete(address)}>
                        <span className="icon is-small">
                          <i className="fa fa-trash"/>
                        </span>
                    </a>
                </td>
            </tr>
        )
    };

    let _computeTranslatedAddress = (address, locale) => {
        let {address_line_1, street_number, city, postal_code} = address;
        let street_number_label = street_number ? street_number : '';
        return `${address_line_1[locale]} ${street_number_label}, ${postal_code} ${city[locale]}`;
    };

    return (
        <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
            <tr>
                <th width="">Français</th>
                <th width="">Néerlandais</th>
                <th width="">Anglais</th>
                <th width="">Principale ?</th>
                <th width="">Actions</th>
            </tr>
            </thead>
            <tbody>
            { addresses && addresses.map( (address, index) => { return _renderAddressLine(address, index) })}
            </tbody>

        </table>
    )
};

export { TableAddress };
