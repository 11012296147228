import React from 'react'
import {useForm} from 'react-hook-form'

import {LOCALES} from '../../shared/constants';
import { Select } from './form_components';

let LocaleSelectorForm = props => {
    const { handleSubmit } = useForm();
    const onSubmit = props.onSubmit;
    const selectedValue = props.selectedValue;

    let handleChange = (event) => {
        const newLocale = event.target.value;
        onSubmit(newLocale);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field is-grouped">
                <div className="control">
                    <div className="select">
                        <Select name="locale" options={LOCALES} onChange={handleChange} value={ selectedValue }/>
                    </div>
                </div>
            </div>
        </form>
    )
};

export default LocaleSelectorForm;
