import React from "react";
import {formatDate} from '../../shared/utils';

const stringValueStyles = {
    padding: '0 0 0 2rem',
};


const lineStyles = {
    margin: '0.5rem 0 0 0',
};

let FeedbackDetails = props => {
    const { feedback, onClose } = props;


    return (
        <div>
            <div className="">
                <h2 className='title is-size-3'>Contact</h2>
                <div className='content'>
                    <div style={lineStyles}>
                        <span className='is-capitalized'>Sent:</span>
                        <span className='p1-2 has-text-weight-bold' style={stringValueStyles}>{formatDate(feedback.created_at)}</span>
                    </div>
                    <div style={lineStyles}>
                        <span className='is-capitalized'>From:</span>
                        <span className='p1-2 has-text-weight-bold' style={stringValueStyles}>{feedback.name}</span>
                    </div>
                    <div style={lineStyles}>
                        <span className='is-capitalized'>Phone/Email:</span>
                        <span className='p1-2 has-text-weight-bold' style={stringValueStyles}>{feedback.contact}</span>
                    </div>
                </div>
                <h2 className='title is-size-3'>Message</h2>
                <p className='content'>
                    {feedback.body}
                </p>
            </div>

            <div className="section columns is-centered">
                <div className="columns is-12">
                    <div className="control">
                        <a className="button is-outlined is-link" onClick={ () => onClose()}>
                            &nbsp;Close
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { FeedbackDetails };
