import React  from 'react'
import {useForm} from 'react-hook-form'

import {Link} from "react-router-dom";


const spaceButtonStyle = {
    margin: '0 0.5rem 0 0'
};

let MessageForm = props => {
    const {register, handleSubmit, errors} = useForm();
    const {message, institutions, onSubmit, cancelURL } = props;


    let textInput = (name, label, defaultValue, required) => {
        const labelName = required ? `${label} *` : label;
        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">{labelName}</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input name={name} ref={register({ required: required })}  type="text" className="input" defaultValue={defaultValue} />
                            {required && errors[name] && <span className="form-error">Ce champs est requis.</span>}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    let textareaInput = (name, label, defaultValue) => {
        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">{label} *</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <textarea name={name} className="textarea" rows="5" ref={register({ required: true })} defaultValue={defaultValue} />
                            {errors[name] && <span className="form-error">Ce champs est requis.</span>}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    let selectInput = (attribute_name, attribute_value, attribute_label, available_values) => {

        return (
            <div className="control columns">
                <div className="column is-full">
                    <label className="label">{attribute_label}</label>
                    <div className="select">
                        <select name={attribute_name} ref={register({ required: true })} defaultValue={attribute_value}>
                            { available_values && available_values.map( (value, index) => {
                                return (
                                    <option key={index} value={value.id}>{value.name.fr}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="title is-3">Titre</h3>
            { textInput('titleFr', 'Français', message.title.fr, true) }
            { textInput('titleNl', 'Néerlandais', message.title.nl, true) }
            { textInput('titleEn', 'Anglais', message.title.en, true) }

            <h3 className="title is-3">Corps du message</h3>
            { textareaInput('bodyFr', 'Français', message.body.fr) }
            { textareaInput('bodyNl', 'Néerlandais', message.body.nl) }
            { textareaInput('bodyEn', 'Anglais', message.body.en) }
`
            <h3 className="title is-3">En lien avec une institution ?</h3>
            { selectInput("institutionId", message.institutionId, "Institution", institutions) }

            <div className="section columns is-centered">
                <div className="columns is-12">
                    <div className="control" style={spaceButtonStyle}>
                        <input type="submit" className="button is-primary" value="Sauver" />
                    </div>
                    <div className="control" style={spaceButtonStyle}>
                        <Link className="button is-light" to={cancelURL}>
                            Annuler
                        </Link>
                    </div>
                </div>
            </div>

        </form>
    )
};

export { MessageForm };
