import React from "react";

const EmptyState = () => (
  <div className="has-text-centered" style={{
    height: 'calc(100vh - 53px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }}>
    <img src="/images/loading.svg" alt="" style={{
      maxWidth: 400,
      marginBottom: 80
    }}/>
    <h1
      style={{
        fontSize: 24
      }}
    >
      Nous chargons votre page
    </h1>
  </div>
)

export default EmptyState;