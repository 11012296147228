import React from "react";
import { withRouter } from "react-router";

import LoginForm from './login_form';

import './login.css';

const Login = () => {

    return (
        <div className="background-image">
            <div className="auth-form">
                <h1 className="title is-size-2">Bienvenue</h1>

                <LoginForm />
            </div>
        </div>

    )
};

export default withRouter(Login);
