import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';


import {DiffComponent} from './DiffComponent';

import Api from '../shared/api';

import {firebaseDb} from "../../config/firebase/firebase";


import './InstitutionDiff.css'
import EmptyState from '../shared/empty_state';

const api = new Api();

class InstitutionDiff extends Component {

    constructor(props) {
        super(props);

        this.state = {
            institutionId: this.props.computedMatch.params.id,
            institution: null,
           
        };

        this._markAsResolved = this._markAsResolved.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }


    loadData() {
        let institutionsRef = firebaseDb.ref(`institutions/${this.state.institutionId}`);
        institutionsRef.on('value', snapshot => {
            const institution = snapshot.val();

            api
            .getInstitutionDiffs(institution.legal_id)
            .then( async (response) => {
                // console.log(response.data.institution)
                this.setState({institution: institution, institutionDocument: response.data.institution, differences: response.data.differences})
            })
            .catch((err) => {
                console.log(err)
            });

        });
    }

    async _markAsResolved() {
        const api = new Api();
        const { institution } = this.state;

        api
            .markInstitutionDiffsAsResolved(institution.legal_id)
            .then( async (response) => {
                this.props.history.push(`/institutions/${institution.id}`);


                let institutionsRef = firebaseDb.ref(`institutions/${institution.id}`);
                institution.news = false
                await institutionsRef.set(institution);

            })
            .catch((err) => {
                console.log(err)
            });


    };


    loadDiff(institutionLegalId) {
        api
            .getInstitutionDiffs(institutionLegalId)
            .then( async (response) => {
                this.setState({institutionDocument: response.data})
            })
            .catch((err) => {
                console.log(err)
            });
    }


    render(){
        const {institution, institutionDocument, differences} = this.state;
        let oldData = this.getDefaultedOldData(institutionDocument);
        let diffValues = this.computeDiffValues(institutionDocument, oldData, differences);
        
        if (institution === null) {
            return (
                <EmptyState />
            )
        }

        return (
            <div>
                <section className="section short-section">
                    <div className="columns">
                        <div className="column is-7">
                            <h1 className="title is-size-2">
                                <Link className="icon subtitle-left-icon" to={ `/institutions/${this.state.institutionId}` }>
                                    <i className="fa fa-angle-left"/>
                                </Link>
                                {institution.name.fr}
                            </h1>
                            <p>Dernière date de mise à jour: {institutionDocument.lastUpdate}</p>
                        </div>

                        <div className="column is-5">
                            <div className="field is-grouped is-pulled-right">
                            
                                <div className="control">
                                    <a className="button is-outlined is-link" onClick={ () => this._markAsResolved()}>
                                        Marquer ces modifications comme résolues
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>

                </section>

                
                { diffValues.map( (row, index) => {
                    return <DiffComponent label={row.label} values={row.values} index={index} />
                })}

                
                
            </div>
        )
    }


    getDefaultedOldData(institutionDocument){
        let data = {
            descriptionFr: ""
        };

        if (!institutionDocument) { return data; }

        if (institutionDocument.old) {
            data = institutionDocument.old.descriptionFr || ""
        }
        return data;
    }

    computeDiffValues(institutionDocument, oldData){
        let data = [];

        if (!institutionDocument){ return data; }

        if (institutionDocument.descriptionDiff) {
            let v = [
                {old: oldData.descriptionFr, new: institutionDocument.descriptionFr},
                {old: oldData.descriptionNl, new: institutionDocument.descriptionNl}
            ];
            data.push({label: "Description", values: v});
        }

        if (institutionDocument.activitiesDiff) {
            let v = [
                {old: oldData.activitiesFr, new: institutionDocument.activitiesFr},
                {old: oldData.activitiesNl, new: institutionDocument.activitiesNl}
            ];
            data.push({label: "Activités", values: v});
        }

        if (institutionDocument.permanencyDiff) {
            let v = [
                {old: oldData.permanencyFr, new: institutionDocument.permanencyFr},
                {old: oldData.permanencyNl, new: institutionDocument.permanencyNl}
            ];
            data.push({label: "Permanences", values: v});
        }
        return data;
    }

}


export  default withRouter(InstitutionDiff);
