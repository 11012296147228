import React  from 'react'
import {useForm} from 'react-hook-form'
import  cloneDeep  from 'clone-deep';

import { ServiceCard } from './ServiceCard'
import {Link} from "react-router-dom";

let InstitutionForm = props => {
    const {register, handleSubmit, errors} = useForm();
    const {institution, services, addictions, accessibilities, prices, languages, onSubmit, cancelURL }= props;
    const locale = 'fr';

    const sortedLanguages = languages.sort((a,b) => { return a.reference.localeCompare(b.reference)})

    let textInput = (name, label, defaultValue, required) => {
        const labelName = required ? `${label} *` : label;
        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">{labelName}</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input name={name} ref={register({ required: required })}  type="text" className="input" defaultValue={defaultValue} />
                            {required && errors[name] && <span className="form-error">Ce champs est requis.</span>}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    let textareaInput = (name, label, defaultValue) => {
        return (
            <div className="field is-horizontal">
                <div className="field-label is-normal">
                    <label className="label">{label} *</label>
                </div>
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <textarea name={name} className="textarea" rows="5" ref={register({ required: true })} defaultValue={defaultValue} />
                            {errors[name] && <span className="form-error">Ce champs est requis.</span>}
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    let radioInput = (name, label, defaultValue) => {
        return (
            <div className="field is-horizontal">
                <div className="field-body">
                    <div className="field">
                        <div className="control is-expanded">
                            <input className="is-checkradio" id={name} name={name} type="checkbox" defaultChecked={defaultValue} ref={register} />
                            <label className="label" htmlFor={name}>{label}</label>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    let serviceInput = (service) => {
        let checkedValue = institution.services ? institution.services.includes(service.reference) : false;

        return (
            <div className="column field is-narrow" key={service.id}>
                <div className="control service-input">
                    <input className="is-checkradio" id={`service_${service.id}`} type="checkbox" name={`serviceId_${service.id}`}
                           defaultChecked={checkedValue} ref={register}/>
                    <label htmlFor={`service_${service.id}`}>
                        <ServiceCard service={service} serviceId={service.id} serviceName={service.name[locale]}/>
                    </label>
                </div>
            </div>
        )
    };

    let serviceGroupInput = (serviceGroup) => {
        return (
            <div key={`serviceGroup_${serviceGroup.id}`}>
                <h4 className="subtitle is-4">{serviceGroup.name.fr}</h4>
                <div className="columns">{ serviceGroup.services.map( (service) => { return serviceInput(service)})}</div>
            </div>
        )
    };

    let genericCheckboxInput = (attribute_label,  attribute_name, available_value, withIcon = true, withLabel= true) => {
        let checkedValue = institution[attribute_name] ? institution[attribute_name].includes(available_value.reference) : false;

        return (
            <div className="column field is-narrow" key={`${attribute_name}Container_${available_value.reference}`}>
                <div className="control service-input">
                    <input className="is-checkradio" id={`${attribute_name}_${available_value.reference}`} type="checkbox" name={`${attribute_name}Ref_${available_value.reference}`}
                           defaultChecked={checkedValue} ref={register}/>
                    <label htmlFor={`${attribute_name}_${available_value.reference}`} key={available_value.reference}>
                        { withIcon && <span className={`dune-${available_value.reference}`}/> }
                        {withLabel && available_value.label.fr}
                    </label>
                </div>
            </div>
        )
    };



    let selectInput = (attribute_name, attribute_value, attribute_label, available_values) => {

        return (
            <div className="control columns">
                <div className="column is-full">
                    <label className="label">{attribute_label}</label>
                    <div className="select">
                        <select name={attribute_name} ref={register({ required: true })} defaultValue={attribute_value}>
                            { available_values && available_values.map( (value, index) => {
                                return (
                                    <option key={index} value={value.reference}>{value.label.fr}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
            </div>
        )
    };



    let addictionsInput = () => {
        return (
            <div key="addictions">
                <div className="columns">{ addictions.map( (addiction) => { return genericCheckboxInput("Assuétudes", "addictions", addiction)})}</div>
            </div>
        )
    };

    let accessibilitiesInput = () => {
        const acc = cloneDeep(accessibilities);
        let accSplitted = [];
        while (acc.length) {
            accSplitted.push(acc.splice(0,3));
        }

        return (
            <div key="accessibilities">
                { accSplitted.map( (accSplit, index) => { return (
                    <div key={`accessiblityGroup${index}`} className="columns">{ accSplit.map( (accessibilitiy) => { return genericCheckboxInput("Accessiblité", "accessibilities", accessibilitiy, false)})}</div>
                )})}
            </div>
        )
    };

    let pricesInput = () => {
        return (
            <div key="prices">
                <div className="columns">{ prices.map( (price) => { return genericCheckboxInput("Prix", "prices", price)})}</div>
            </div>
        )
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h3 className="title is-3">Nom de l'institution</h3>
            { textInput('name_fr', 'Français', institution.name.fr, true) }
            { textInput('name_nl', 'Dutch', institution.name.nl, true) }
            { textInput('name_en', 'English', institution.name.en, true) }

            <h3 className="title is-3">Numéro d'entreprise</h3>
            { textInput('legal_id', "Numéro d'entreprise", institution.legal_id, false) }

            <h3 className="title is-3">Description</h3>
            { textareaInput('description_fr', 'Français', institution.description.fr) }
            { textareaInput('description_nl', 'Dutch', institution.description.nl) }
            { textareaInput('description_en', 'English', institution.description.en) }

            <h3 className="title is-3">Langues</h3>
            { selectInput("main_language", institution.main_language, "Langue principale", sortedLanguages) }
            <label className="label">Langues parlées au sein de l'institution</label>
            { sortedLanguages.map( (language) => {
                return  radioInput(`languages${language.reference}`, language.label.fr, institution.languages.includes(language.reference));
            })}
`
            <h3 className="title is-3">Autres</h3>
            { textInput('phone', 'Phone', institution.phone) }
            { textInput('web', 'Site Web', institution.web) }
            { radioInput('hospital', 'Hôpital', institution.hospital) }
            { radioInput('prm', 'Accès pour personne à mobilité réduite', institution.prm) }
            { radioInput('cpas', 'cpas', institution.cpas) }

            <h3 className="title is-3">Services</h3>
            {
                services.map( (service) => { return serviceGroupInput(service) })
            }
            <h3 className="title is-3">Assuétudes</h3>
            { addictionsInput() }

            <h3 className="title is-3">Accessibilité</h3>
            { accessibilitiesInput() }

            <h3 className="title is-3">Prix</h3>
            { pricesInput() }


            <div className="section columns is-centered">
                <div className="columns is-12">
                    <div className="control">
                        <input type="submit" className="button is-primary" value="Sauver" />
                    </div>
                    <div className="control">
                        <Link className="button is-light" to={cancelURL}>
                            Annuler
                        </Link>
                    </div>
                </div>
            </div>

        </form>
    )
};

export { InstitutionForm };
