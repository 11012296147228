import React, { Component } from "react";

import {formatDate} from '../../shared/utils';
import Api from '../shared/api';

import Pagination from '../shared/Pagination';
import {FeedbackDetails} from "./FeedbackDetails";
import Modal from 'react-modal';

const customStyles = {
    content : {
        top                   : '10%',
        left                  : '20%',
        right                 : '20%',
        bottom                : '10%',
    }
};
Modal.setAppElement('#root');


const api = new Api();

class Feedbacks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            feedbacks: [],
            page: 1,
            perPage: 25,
            totalPages: 1,
            totalRecords: 0,
            modalIsOpen: false
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

    }

    componentDidMount() {
        // no previous load, will be triggered by the pagination
        // this.loadData(1);
    }

    async loadData(page) {
        const { perPage } = this.state;
        try {
            const {data: { feedbacks, metadata }} = await api.getFeedbacks(page, perPage);
            this.setState({
                feedbacks: feedbacks,
                totalPages: metadata["total_pages"],
                totalRecords: metadata["total_records"],
                currentPage: metadata["current_page"],
                firstPage: metadata["first_page"],
                lastPage: metadata["last_page"],
            })
        } catch(e) {
            console.log(e)
        }
    }

    onPageChanged = data => {
        const { currentPage } = data;
        this.loadData(currentPage);
        this.setState({page: currentPage});
    }

    openModal = (feedback) => {
        this.setState({modalIsOpen: true, feedback: feedback});
    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }
    closeModal = () => {
        if (! this.state.modalIsOpen) { return }
        this.setState({modalIsOpen: false});
    }


    render() {
        const { feedbacks, totalPages } = this.state;
        const _openModal = this.openModal;
        const _closeModal = this.closeModal;

        return (
            <div>
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    style={customStyles}
                    contentLabel="Modification adresse"
                >
                    <h1 className='title is-size-2'>Feedback</h1>
                    <FeedbackDetails feedback={this.state.feedback} onClose={_closeModal} />
                </Modal>

                <section className="section">
                    <div className="columns">
                        <div className="column is-10">
                            <h1 className="title is-size-2">Feedbacks</h1>
                        </div>
                    </div>

                    <div className="columns is-multiline">

                        <table className="table is-striped is-hoverable is-fullwidth">
                            <thead>
                            <tr>
                                <th width="10%">Nom</th>
                                <th width="20%">Contact</th>
                                <th width="20%">Date</th>
                                <th width="40%">Message</th>
                                <th width="10%">Action</th>
                            </tr>
                            </thead>
                            <tbody>

                            { feedbacks != null && feedbacks.map(function(feedback, index){
                                return (
                                    <tr key={index}>
                                        <td> {feedback.name} </td>
                                        <td> {feedback.contact} </td>
                                        <td> {formatDate(feedback.created_at)} </td>
                                        <td> {feedback.body} </td>
                                        <td>
                                            <button className="button is-outlined is-link" onClick={ () => _openModal(feedback)}>
                                                <span className="icon is-small">
                                                    <i className="fa fa-eye"></i>
                                                </span>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>

                    <div className="columns">
                        <div className="d-flex flex-row py-4 align-items-center">
                            <Pagination totalPages={totalPages} pageNeighbours={2} onPageChanged={this.onPageChanged} />
                        </div>
                    </div>

                </section>
            </div>

        )
    }
}


export default Feedbacks;
