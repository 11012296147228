import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Simplert from 'react-simplert';

import './EditSchedules.css'
import ScheduleForm from './ScheduleForm';
import {firebaseDb} from "../../config/firebase/firebase";


import {DAYSMAPPINGS} from '../../shared/constants';
import {aggregateSchedule} from '../shared/aggregateSchedule';

import Api from '../shared/api';
import EmptyState from '../shared/empty_state';


class EditSchedules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            institutionId: props.computedMatch.params.id,
            institution: null,
            selectedScheduleIndex: null,
            selectedSchedule: null,
            showDeleteAlert: false,
        };

        this._selectSchedule = this._selectSchedule.bind(this);
        this._deleteSchedule = this._deleteSchedule.bind(this);
        this._askToDeleteSchedule = this._askToDeleteSchedule.bind(this);
        this._addNewSchedule = this._addNewSchedule.bind(this);
    }

    componentDidMount(){
        this.loadData();
    }

    async loadData() {
        const api = new Api()
        const { data: { institution } } = await api.getInstitutionById(this.state.institutionId);;
        if (!institution.schedules) { institution.schedules = [];}
        const selectedSchedule = institution.schedules[0];
        this.setState({institution, selectedSchedule, selectedScheduleIndex: 0});
    }

    _selectSchedule(scheduleIndex){
        const selectedSchedule = this.state.institution.schedules[scheduleIndex];
        this.setState({selectedSchedule: selectedSchedule, selectedScheduleIndex: scheduleIndex});
    }

    _addNewSchedule = () => {
        let { institution } = this.state;
        let newSchedule = {
            name: {fr: 'Nouvel horaire', nl:' Nieuwe', en:'New schedule'},
            openingHours: []
        };
        institution.schedules.push(newSchedule);
        this.setState({institution});
    };

    _askToDeleteSchedule = () => {
        this.setState({showDeleteAlert: true});
    };

    _hideSimplert = () => {
        this.setState({showDeleteAlert: false});
    };

    _deleteSchedule = async () => {
        let { institution, selectedScheduleIndex } = this.state;
        institution.schedules.splice(selectedScheduleIndex, 1);
        institution.summarySchedule = aggregateSchedule(institution.schedules);

        try {
            const api = new Api();
            const response = await api.updateSchedules(institution.id, {
                schedules: institution.schedules,
                summary_schedule: institution.summarySchedule
            })
            //console.log(response);
        } catch(e) {
            console.log(e)
        }


        this.setState({showDeleteAlert: false, selectedScheduleIndex: 0, selectedSchedule: institution.schedules[0]});
        this.loadData();
    };

    _submit = async (scheduleNewValues) => {
        let scheduleIndex = parseInt(scheduleNewValues.scheduleIndex);
        let {institution} = this.state;
        let {schedules} = institution;
        let schedule = schedules[scheduleIndex];
        scheduleNewValues.openingHours.map( (openingHour) => {
            openingHour.day = parseInt(openingHour.day);

            let oldOpeningHour = schedule.openingHours ? schedule.openingHours.filter( (s) => { return s.day === openingHour.day })[0] : undefined;
            // eslint-disable-next-line array-callback-return

            switch(openingHour.allDayOpen) {
                case "true":
                    openingHour.allDayOpen = true;
                    break;
                case "false":
                    openingHour.allDayOpen = false;
                    break;
                default:
                    openingHour.allDayOpen = oldOpeningHour === undefined ? false : oldOpeningHour.allDayOpen;
            }

            switch(openingHour.variable) {
                case "true":
                    openingHour.variable = true;
                    break;
                case "false":
                    openingHour.variable = false;
                    break;
                default:
                    openingHour.variable =  oldOpeningHour === undefined ? false : oldOpeningHour.variable;
            }
            if (openingHour.variable || openingHour.allDayOpen) {
                openingHour.from = "";
                openingHour.to = "";
                openingHour.closeFrom = "";
                openingHour.closeTo = "";
            }
        });
        schedules[scheduleIndex] = scheduleNewValues;
        schedules[scheduleIndex].addressId = parseInt(scheduleNewValues.addressId);
        institution.summarySchedule = aggregateSchedule(schedules);

        
        try {
            const api = new Api();
            const response = await api.updateSchedules(institution.id, {
                schedules: schedules,
                summary_schedule: institution.summarySchedule
            })
            //console.log(response);
        } catch(e) {
            console.log(e)
        }
    };

    render() {
        const {institution} = this.state;
        if (!institution) {
            return ( <EmptyState /> )
        }

        const { schedules } = institution;

        return (
            <div>
                <section className="section">
                    <div className="columns">
                        <div className="column is-8">
                            <h1 className="title is-size-2">
                                <Link className="icon subtitle-left-icon" to={`/institutions/${institution.id}`}>
                                    <i className="fa fa-angle-left"/>
                                </Link>
                                {institution.name.fr}
                            </h1>
                        </div>
                    </div>
                    <div className="columns">
                        { this._renderMenu() }
                        { schedules.map( (schedule, index) => {
                            let mappedSelectedSchedule = this._mapSchedule(schedule);
                            let visibleClass =  index === this.state.selectedScheduleIndex ? '' : 'hidden';
                            return (
                                <div className={`column is-four-fifth ${visibleClass}`} key={index}>
                                    <ScheduleForm schedule={mappedSelectedSchedule} scheduleIndex={index} addresses={this.state.institution.addresses} onSubmit={this._submit}/>

                                    {schedules.length > 1 && this._displayDangerZone() }
                                </div>
                            )
                        })}
                    </div>

                </section>
                <Simplert
                    showSimplert={this.state.showDeleteAlert}
                    type="warning"
                    title="Attention"
                    message="Vous êtes sur le point d'effacer un schedule. Êtes-vous certain ?"
                    useConfirmBtn={true}
                    customConfirmBtnText="Je confirme"
                    customCloseBtnText="Annuler"
                    onConfirm={this._deleteSchedule}
                    onClose={this._hideSimplert}
                />
            </div>
        )
    }

    _displayDangerZone = () => {
        return (
            <div className="box">
                <h3 className="title is-4">Zone de danger</h3>
                <div className="buttons">
                    <button className="button is-danger"
                            onClick={() => this._askToDeleteSchedule()}>
                        Effacer le schedule
                    </button>
                </div>
            </div>
        )
    };

    _renderMenu() {
        let { institution, selectedScheduleIndex } = this.state;

        if (institution === null) { return }

        return (
            <div className="column is-one-fifth lateral-menu">
                <aside className="menu">
                    <p className="menu-label">Schedules</p>
                    <ul className="menu-list">
                        {institution.schedules.map((schedule, index) => {
                            const className = ( index === selectedScheduleIndex ) ? 'is-active' : '';
                            return (
                                <li className={className} key={index}>
                                    <a onClick={ () => this._selectSchedule(index)}>
                                        {schedule.name.fr}
                                    </a>
                                </li>
                            )
                        })
                        }
                        <div className="bottom-actions">
                            <div className="buttons">
                                <button className="button is-primary"
                                        onClick={ () => this._addNewSchedule()}>
                                    Ajouter un nouveau schedule
                                </button>
                            </div>
                        </div>
                    </ul>
                </aside>
            </div>
        )
    }

    _mapSchedule = (schedule) => {
        let mappedSchedule = {
            addressId: schedule.addressId,
            name: schedule.name,
            openingHours: {}
        };

        // eslint-disable-next-line array-callback-return
        DAYSMAPPINGS.map( (day, dayNumber) => {
            let  openingHour = {allDayOpen: false, variable: false, closeFrom: null, closeTo: null, day: dayNumber, from: null, to: null, description: {fr: null, nl: null, en: null} };

            if (schedule.openingHours !== undefined) {
                let openingHourTmp = schedule.openingHours.filter( (oh) => { return oh.day === dayNumber } )[0];

                if (openingHourTmp !== undefined) {
                    openingHour = openingHourTmp
                }
            }
            mappedSchedule.openingHours[dayNumber] = openingHour;
        });

        return mappedSchedule;
    }

}

export { EditSchedules };
