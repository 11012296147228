import React from 'react'

import ReactDiffViewer from 'react-diff-viewer';


let DiffComponent = props => {
    const { label, values, index} = props;


    return (
        <section className="section" key={index}>
            <h2 className="title is-size-3">{label}</h2>
            { values && values.map( (value, indx) => {
                 return <ReactDiffViewer oldValue={value.old} newValue={value.new} splitView={true} key={`${index}-${indx}`} />
            })}
        </section>
    )
};

export { DiffComponent }
