import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';


const AuthenticatedRoute = ({component: Component, authenticated, location, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                return authenticated ? (
                    <Component {...rest} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: {referrer: location},
                        }}
                    />
                );
            }}
        />
    );
};


export default withRouter(AuthenticatedRoute);
