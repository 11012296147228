export const institution_addictions = [
    {reference: "alcohol", label:{fr:"Alcool", nl:"Alcohol", en:"Alcohol"}},
    {reference: "drugs", label:{fr:"Drogues illégales", nl:"Illegale drugs", en:"Illegal drugs"}},
    {reference: "games", label:{fr:"Jeux", nl:"Spellen", en:"Games"}},
    {reference: "tobacco", label:{fr:"Tabac", nl:"Tabak", en:"Tobacco"}}
];

export const institution_accessibilities = [
    {reference: "belgian", label:{fr:"Être inscrit.e au registre de la population", en:"Be registered in the population register", nl:"Wees ingeschreven in het bevolkingsregister"}},
    {reference: "brussels", label:{fr:"Personnes résidant sur le territoire de la ville de Bruxelle", nl:"Personen die op het grondgebied van de stad Brussel wonen", en:"Persons residing in the territory of the city of Brussels"}},
    {reference: "city", label:{fr:"Personnes résidantes de la commune", nl:"Inwoners van de gemeente", en:"Residents of the municipality"}},
    {reference: "drugs", label:{fr:"Personnes consommatrices de drogues", nl:"druggebruikers", en:"Drug users"}},
    {reference: "hobo", label:{fr:"Services réservés pour les personnes sans domicile fixe", en:"Services reserved for homeless people", nl: "Diensten voorbehouden aan daklozen"}},
    {reference: "women", label:{fr:"Femmes avec ou sans enfants", nl:"Vrouwen met of zonder kinderen", en:"Women with or without children"}},
    {reference: "men", label:{fr:"Hommes", nl:"Mannen", en:"Men"}},
    {reference: "minors", label:{fr:"Personnes mineures", nl:"Minderjarigen", en:"Minors"}},
    {reference: "legal", label:{fr:"Être en ordre de séjour sur le territoire", nl:"Op voorwaarde van wettelijk verblijf", en:"Be in order to stay in the Belgian territory"}},
    {reference: "health_insurance", label:{fr:"Nécessité d'avoir une mutualité belge en ordre", nl:"Op voorwaarde van inschrijving bij een Belgisch ziekenfonds", en:"Registration with a Belgian health insurance fund"}},
    {reference: "precariousness", label:{fr: "Service réservé aux personnes en situation de séjour précaire", nl:"Dienst voorbehouden voor mensen die zich in precaire verblijfsituatie bevinden", en:"Service reserved for people in a precarious stay situation"}},
];

export const institution_prices = [
    {reference: "free", label:{fr:"Gratuit", nl:"Gratis", en:"Free"}},
    {reference: "paying", label:{fr:"Payant", nl:"Betaald", en:"Paying"}},
    {reference: "income_proportional", label:{fr:"Payants en fonction des revenus", nl:"Betaald op basis van de inkomsten", en:"Paid according to income"}},
    {reference: "partially_paid", label:{fr:"Partiellement payants", nl:"Deels betaald", en:"Partially paid"}},
    {reference: "lump_sump", label:{fr:"Paiements forfaitaires", nl:"Forfaitaire betaling", en:"Lump sum payments"}},
    {reference: "fee_for_service", label:{fr:"Paiement à l'acte", nl:"Betaling per prestatie", en:"Fee-for-service"}},
];

export const institution_languages = [
    {reference: "ar", label:{fr:"Arabe", nl:"Arabische", en:"Arabic"}},
    {reference: "bg", label:{fr:"Bulgare", nl:"Bulgaar", en:"Bulgarian"}},
    {reference: "en", label:{fr:"Anglais", nl:"Engels", en:"English"}},
    {reference: "fr", label:{fr:"Français", nl:"Frans", en:"French"}},
    {reference: "de", label:{fr:"Allemand", nl:"Duitse", en:"German"}},
    {reference: "es", label:{fr:"Espagnol", nl:"Spaans", en:"Spanish"}},
    {reference: "it", label:{fr:"Italien", nl:"Italiaanse", en:"Italian"}},
    {reference: "lg", label:{fr:"Luganda", nl:"Luganda", en:"Luganda"}},
    {reference: "nl", label:{fr:"Néerlandais", nl:"Nederlands", en:"Dutch"}},
    {reference: "peul", label:{fr:"Peul", nl:"Peul", en:"Peul"}},
    {reference: "pl", label:{fr:"Polonais", nl:"Poolse", en:"Polish"}},
    {reference: "pt", label:{fr:"Portugais", nl:"Portugese", en:"Portuguese"}},
    {reference: "ro", label:{fr:"Roumain", nl:"Roemeense", en:"Romanian"}},
    {reference: "ru", label:{fr:"Russe", nl:"Russian", en:"Russisch"}},
    {reference: "tr", label:{fr:"Turc", nl:"Turkse", en:"Turkish"}}
];
