import React, { useState, useContext } from "react";
import {firebaseDb} from '../config/firebase/firebase';

const ServiceGroupsContext = React.createContext({});

const ServiceGroupsProvider = ({ children }) => {
    const [serviceGroups, setServiceGroups] = useState(null);

    if (serviceGroups === null || serviceGroups.length === 0) {
        let serviceGroupsRef = firebaseDb.ref('serviceGroups');
        serviceGroupsRef.on('value', snapshot => {
            const serviceGroupsData = snapshot.val();
            setServiceGroups({serviceGroups: serviceGroupsData});
        });
    }

    return (
        <ServiceGroupsContext.Provider value={serviceGroups}>
            {children}
        </ServiceGroupsContext.Provider>
    );
};

const withServiceGroups = (Component) =>
    (props) => {
        const serviceGroups = useContext(ServiceGroupsContext);
        return (<Component {...props} serviceGroups={serviceGroups}/>)
    };

export { ServiceGroupsContext, ServiceGroupsProvider, withServiceGroups };
