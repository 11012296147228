import Geo from 'geo-nearby';
import {StibStops} from './stibStops';
import {StibRoutes} from './stibRoutes';

import { arrayfy }from './utils';

let prepareStibStops = function() {
    const stopsOriginalData = Object.values(StibStops);
    const stopsData = stopsOriginalData.map( (sd) => {
        return {id: sd.id, lat: sd.gpsCoordinates.latitude, lon: sd.gpsCoordinates.longitude, name: sd.name.fr  }
    });
    let compactedDataSet = Geo.createCompactSet(stopsData, {id: 'id', name: 'name', lat: 'lat', lon: 'lon'});

    return {
        originalData: stopsOriginalData,
        dataset: compactedDataSet
    };
};


let enrichLocationWithStibStops = function(location, stops, radius = 100) {
    let geoStops = new Geo(stops.dataset, {sorted: true});
    let nearbyStops = geoStops.nearBy(location.latitude, location.longitude, radius);
    if (nearbyStops.length === 0 && radius <= 500) {
        return enrichLocationWithStibStops(location, stops, (radius + 50));
    }

    if (nearbyStops.length > 0) {
        let index = nearbyStops.map((e) => {
            return e.i
        });
        let finalStops = [];
        for (let i = 0; i < index.length; i++) {
            let stop = stops.originalData.filter( (s) => {return s.id === index[i]})[0];
            stop.transporter = 'stib';
            finalStops.push(stop);
        }
        finalStops = _enrichStopsWithRoutes(finalStops);

        let routesCount = finalStops.map( (s) => { return Object.keys(s.routes).length } ).reduce( (p,c) => { return p + c}, 0);
        if ( routesCount === 0 && radius <= 500) {
            return enrichLocationWithStibStops(location, stops, radius + 50);
        }

        let groupedFinalStops = finalStops.reduce((p, c) => {
                let existingStop = p.filter((s) => {
                    return s.name.fr === c.name.fr
                })[0];
                if (existingStop) {
                    let existingRouteNames = Object.keys(existingStop.routes);

                    let newRoutes = Object.values(c.routes).filter((r) => {
                        return !existingRouteNames.includes(r.shortName)
                    });
                    if (newRoutes && newRoutes.length > 0) {
                        newRoutes.map( (nr) => { existingStop.routes[nr.shortName] = nr });
                    }
                } else {
                    p.push(c);
                }
                return p;
            },
            []
        );


        let notEmptyRoutes = groupedFinalStops.filter((e) => {
            return Object.keys(e.routes).length > 0
        });

        notEmptyRoutes.map( (e) => { e.routes = Object.values(e.routes)});
        notEmptyRoutes.map( (e) => { e.routes = arrayfy(e.routes)});

        return notEmptyRoutes;
    }
};

let _enrichStopsWithRoutes = function(finalStops) {
    const processingStops = [...finalStops];

    let processedStops = [];
    for (let i = 0; i < processingStops.length; i++) {
        let fs = Object.assign({}, processingStops[i]);

        delete fs.gpsCoordinates;
        fs.name.en = fs.name.fr;
        fs.routes = {};
        let avRoutes = StibRoutes.filter((r) => r.stopIds.includes(fs.id));
        avRoutes.map((ar) => {
            if (fs.routes[ar.shortName] === undefined) {
                fs.routes[ar.shortName] = {
                    routeId: ar.routeId,
                    shortName: ar.shortName,
                    longName: ar.longName,
                    color: ar.color,
                    textColor: ar.textColor,
                    type: ar.type
                }
            }
        });
        processedStops.push(fs);
    }
    return processedStops;
};


export { enrichLocationWithStibStops, prepareStibStops };
