import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import Api from "../shared/api";

const api = new Api();

const AuthenticatedNavbar = () => {
  const { logout } = useAuth0();
  const [isAdmin, setIsAdmin] = useState(false);
  
  useEffect(() => {
    const getCheck = async () => {
      try {
        const { data: { profile: { role }}} = await api.getProfile();
        setIsAdmin(role === "admin")
      } catch(e) {
        setIsAdmin(false)
      }
    }

    getCheck()
  }, [])

  return (
    <>
      <div className="navbar-start">
        <Link className="navbar-item" to="/dashboard">
          Dashboard
        </Link>

        <Link className="navbar-item" to="/institutions">
          Institutions
        </Link>
        {isAdmin &&
          <>
            <Link className="navbar-item" to="/messages">
              Messages
            </Link>

            <Link className="navbar-item" to="/feedbacks">
              Feedbacks
            </Link>
          </>
        }
      </div>
      <div className="navbar-item">
        <button className="navbar-item button is-outlined is-pulled-right is-link" onClick={logout}>
          <i className="fa fa-sign-out" />
        </button>
      </div>
    </>
  );
};

export default AuthenticatedNavbar;
