import React from 'react';
import { Route, Redirect } from 'react-router-dom';


const UnauthenticatedRoute = ({component: Component, authenticated, ...rest}) => (
  <Route
    {...rest}
    render={props => {
      return authenticated ? (
        <Redirect
          to={{
            pathname: ( props.location.state === undefined || props.location.state.referrer === undefined) ? '/' : props.location.state.referrer.pathname,
            state: { from: props.location },
          }}
        />
      ) : (
        <Component {...props}/>
      );
    }}
  />
);


export default UnauthenticatedRoute;
