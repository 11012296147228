import React, { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter, Switch} from 'react-router-dom';
import './App.css';
import './font-style.css';
import 'bulma-extensions/dist/js/bulma-extensions.min';
import 'bulma-extensions/bulma-checkradio/dist/css/bulma-checkradio.min.css';

import AuthenticatedRoute from './routes/AuthenticatedRoute';
import UnauthenticatedRoute from './routes/UnauthenticatedRoute';
import Institutions from './components/institutions/Institutions';
import DetailedInstitution from './components/institution/DetailedInstitution';
import NewInstitution from './components/institution/NewInstitution';
import { InstitutionHeader } from './components/institution/InstitutionHeader';
import { EditSchedules } from './components/institution/EditSchedules';
import { EditAddresses } from './components/institution/EditAddresses';
import EditInstitution from './components/institution/EditInstitution';
import InstitutionDiff from './components/institution/InstitutionDiff';


import Messages from './components/messages/Messages';
import NewMessage from './components/messages/NewMessage';
import Feedbacks from "./components/feedbacks/Feedbacks";
import Clients from "./components/clients/Clients";
import ClientNew from "./components/clients/ClientNew";
import ClientEdit from "./components/clients/ClientEdit";

import Navbar from './components/navbar/Navbar';
import Login from './components/login/Login';
import { useLocalstorage } from './hooks/use_localstorage';
import { useAuth0 } from "@auth0/auth0-react";

const { REACT_APP_AUTH_API_AUDIENCE } = process.env;

function App() {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [_, setToken] = useLocalstorage("TOKEN", null);

  useEffect(() => {
    const login = async () => {
      if(!isAuthenticated) { return }

      const token = await getAccessTokenSilently({
        audience: `${REACT_APP_AUTH_API_AUDIENCE}`,
        scope: 'read:current_user'
      });

      setToken(token);
    }
    login();
  }, [isAuthenticated])

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const isLogged = isAuthenticated;
  return (
      <BrowserRouter>
        <div>
          <Navbar className="custom" isLogged={isLogged}/>
          <div>
            <Switch>
              <AuthenticatedRoute authenticated={isLogged} component={Institutions} path="/" exact initial/>
              <UnauthenticatedRoute authenticated={isLogged} component={Login} path="/login"/>

              <AuthenticatedRoute authenticated={isLogged} component={NewInstitution} path="/institutions/new" exact/>
              <AuthenticatedRoute authenticated={isLogged} component={DetailedInstitution} path="/institutions/:id" exact/>
              <AuthenticatedRoute authenticated={isLogged} component={InstitutionHeader} path="/institutions/:id/header" exact/>
              <AuthenticatedRoute authenticated={isLogged} component={EditAddresses} path="/institutions/:id/addresses" exact/>
              <AuthenticatedRoute authenticated={isLogged} component={EditSchedules} path="/institutions/:id/schedules" exact/>
              <AuthenticatedRoute authenticated={isLogged} component={EditInstitution} path="/institutions/:id/edit"/>
              <AuthenticatedRoute authenticated={isLogged} component={InstitutionDiff} path="/institutions/:id/diff"/>
              <AuthenticatedRoute authenticated={isLogged} component={Institutions} path="/institutions" exact/>
              <AuthenticatedRoute authenticated={isLogged} component={ClientNew} path="/clients/new"/>
              <AuthenticatedRoute authenticated={isLogged} component={ClientEdit} path="/clients/:id/edit"/>
              <AuthenticatedRoute authenticated={isLogged} component={Clients} path="/clients"/>

              
              <AuthenticatedRoute authenticated={isLogged} component={Messages} path="/messages"/>
              <AuthenticatedRoute authenticated={isLogged} component={NewMessage} path="/message/new"/>

              <AuthenticatedRoute authenticated={isLogged} component={Feedbacks} path="/feedbacks"/>

            </Switch>
          </div>
        </div>
        <ToastContainer />
      </BrowserRouter>
  );
}


export default App;
