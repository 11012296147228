import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Link } from "react-router-dom";
import AuthenticatedNavbar from "./Authenticated";
import PublicNavbar from "./Public";

const Navbar = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img src="logo.png" alt="Dune" />
        </Link>
      </div>
      <div className="navbar-menu">
        {isAuthenticated ? <AuthenticatedNavbar /> : <PublicNavbar />}
      </div>
    </nav>
  );
};

export default Navbar;
