import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Api from "../shared/api";
import EmptyState from "../shared/empty_state";

import ClientForm from "./Form";

const api = new Api();

const EditClient = () => {
  const history = useHistory();
  let { id } = useParams();
  const [client, setClient] = useState(null);


  useEffect(() => {
    const getClient = async () => {
      const {
        data: {
          client
        },
      } = await api.getClient(id);      
      setClient(client)
    }

    getClient()
  }, []);

  const onSubmit = async ({name, languages}) => {
    try {
      await api.updateClient(id, {languages})
      history.push("/clients");
    } catch (e) {
      console.log(e);
    }
  };

  if(!client) {
    return <EmptyState />
  }

  return (
    <div>
      <section className="section">
        <div className="columns">
          <div className="column is-10">
            <h1 className="title is-size-2">Edition client</h1>
          </div>
        </div>
      </section>
      <ClientForm client={client} onSubmit={onSubmit} />
    </div>
  );
};

export default EditClient;
