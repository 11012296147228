import React from 'react'
//import ReactTooltip from 'react-tooltip'

let ServiceCard = props => {
    const { service } = props;


    return (
        <span key={service.reference} className={`dune-${service.reference}`}/>
    )
};

export { ServiceCard }
