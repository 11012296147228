let jsonCopy = (src) => {
    return JSON.parse(JSON.stringify(src));
};

let timeToDecimal = (t) => {
    if ( !t ) { return null }
    if ( t === '') { return null }

    let arr = t.split(':');
    let dec = parseInt((arr[1]/6) * 10, 10);

    return parseFloat(parseInt(arr[0], 10) + '.' + (dec<10?'0':'') + dec);
};

let aggregateSchedule = (schedules) => {

    if (schedules === undefined) { return {}; }
    let openingHours = [];
    // let summarySchedule = generateEmptySchedule();

    if (!schedules) { return openingHours; }

    // eslint-disable-next-line array-callback-return
    schedules.map((s) => {
        if (s.openingHours) {
            let openingHoursEnriched = s.openingHours.map((oh) => {
                return {
                    day: oh.day,
                    from: timeToDecimal(oh.from),
                    to: timeToDecimal(oh.to),
                    closeFrom: timeToDecimal(oh.closeFrom),
                    closeTo: timeToDecimal(oh.closeTo),
                    allDayOpen: oh.allDayOpen,
                    variable: oh.variable,
                    addressId: parseInt(s.addressId)
                };
            });
            openingHours = openingHours.concat(openingHoursEnriched);
        }
    });

    return jsonCopy(openingHours);
};

export { aggregateSchedule };
