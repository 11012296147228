
// Firebase Project Name: Dune Le Bon Plan
export const firebaseConfig =  {
    apiKey: "AIzaSyA4dKM_bCaVhuIVSEmN4TvlBUXJ87hDfqY",
    authDomain: "dune-le-bon-plan-v2.firebaseapp.com",
    databaseURL: "https://dune-le-bon-plan-v2.firebaseio.com",
    projectId: "dune-le-bon-plan-v2",
    storageBucket: "dune-le-bon-plan-v2.appspot.com",
    messagingSenderId: "376476969651",
    appId: "dune-le-bon-plan-v2",
    measurementId: "G-MEASUREMENT_ID",
};


export const firebaseDevConfig = {
    apiKey: "AIzaSyDpij6r1s7QRlsRSgwXmZsi3xS_WDV1k3Y",
    authDomain: "uat-dune-le-bon-plan-2021.firebaseapp.com",
    databaseURL: "https://uat-dune-le-bon-plan-2021-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "uat-dune-le-bon-plan-2021",
    storageBucket: "uat-dune-le-bon-plan-2021.appspot.com",
    messagingSenderId: "206159309563",
    appId: "1:206159309563:web:e19b4eb0fa1f214d0b5e5e",
    measurementId: "G-FEQT40W2K3"
};
