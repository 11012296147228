import React from 'react';
import ReactDOM from 'react-dom';


import 'bulma/css/bulma.css';
import './index.css';


import {AuthProvider} from "./shared/auth_provider";
import {ServicesProvider} from "./shared/services_provider";
import {ServiceGroupsProvider} from "./shared/service_groups_provider";
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
    <AuthProvider>
        <ServicesProvider>
            <ServiceGroupsProvider>
                <App />
            </ServiceGroupsProvider>
        </ServicesProvider>
    </AuthProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

