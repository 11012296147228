import { useState } from "react";

const PREFIX = "DUNE::";

const useLocalstorage = (key, defaultValue) => {
  const storageKey = `${PREFIX}${key}`;
  // State to store the current Value
  const [localStorageValue, setLocalStorageValue] = useState(() => {
    const item = window.localStorage.getItem(storageKey);
    return item ? JSON.parse(item) : defaultValue;
  });

  const saveValueToLocalStorage = (value) => {
    setLocalStorageValue(value);
    window.localStorage.setItem(storageKey, JSON.stringify(value));
  };

  // Create a setter for the localStorage
  // return the value and the setter
  return [localStorageValue, saveValueToLocalStorage];
};

export { useLocalstorage };
