import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";

const {
    REACT_APP_AUTH_DOMAIN,
    REACT_APP_AUTH_CLIENT_ID,
    REACT_APP_AUTH_API_AUDIENCE,
    REACT_APP_AUTH_API_SCOPE,
} = process.env;

export const AuthProvider = ({ children }) => (
    <Auth0Provider
        domain={`${REACT_APP_AUTH_DOMAIN}`}
        clientId={`${REACT_APP_AUTH_CLIENT_ID}`}
        audience={`${REACT_APP_AUTH_API_AUDIENCE}`}
        scope={`${REACT_APP_AUTH_API_SCOPE}`}
        redirectUri={window.location.origin}
    >
        {children}
    </Auth0Provider>
);
