import React from "react";

export function Input({ register, name, ...rest }) {
    return <input name={name} ref={register} {...rest} />;
}

export function Select({ register, options, name, selectedValue, ...rest }) {
    return (
        <select name={name} ref={register} {...rest}>
            {options.map( (item, index) => (
                <option key={index} value={item.value} selected={item.value === selectedValue ? 'selected' : null }>{item.label}</option>
            ))}
        </select>
    );
}
